import React from "react";
import { useNavigate } from "react-router-dom";

export const AgencyBanner = () => {
   const navigation = useNavigate();
  return (
    <div className="bg-video-wrap2 agency-banner" data-aos="fade-up" data-aos-delay="150">
      {/* <div className="overlay"></div> */}
      <div className="home-video-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h1 className="banner-heading mb-2 text-uppercase">Agency</h1>
              <h1 className="banner-heading fs-2 mb-2 text-uppercase">Creativity | Predictability | Scalability</h1>
              <p className="text-white text-center text-capitalize mb-0">We stand for what we commit and what we deliver</p>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => {
                    navigation("/ContactUs");
                  }}
                  className="btn mt-4 btn-primary contact-btn rounded-0"
                >
                  Get In Touch
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
