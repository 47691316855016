import React, { useState } from "react";
import FooterLogo from "../../assets/images/logo-footer.webp";
import ThankYouModal from "../ThankYouModal";
import { Link } from "react-router-dom";
export const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [thankYouModal, setThankYouModal] = useState(false);
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const setFieldData = (e, key) => {
    setData({ ...data, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  const validate = async (e) => {
    e.preventDefault();
    let flag = true;
    setLoading(true);
    const currentDate = new Date();

    if (!data.emailId?.length > 0) {
      flag = false;

      setValidation({ emailId: "Email is Required" });
    }

    if (flag) {
      try {
        let inputValue = {
          EmailId: data.emailId,
          Date: currentDate.toDateString(),
        };

        let form_data = new FormData();
        for (let key in inputValue) {
          form_data.append(key, inputValue[key]);
        }
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbxowbBOjJ-xAC75gyKyoteNI9pmnL_zZiZdm75WccXA00bLpsq2l9xAmQTvvMTz/exec", // Replace with the URL from the deployed web app
          {
            method: "POST",
            body: form_data,
          }
        );

        const result = await response.text();
        setLoading(false);
        setData({});
        e.target.reset();
        setThankYouModal(true);
      } catch (error) {
        setLoading(false);
        console.error("Error writing to Google Sheets:", error);
      }
    } else {
      setLoading(false);
    }
  };
  return (
    <div className="">
      <a
        href="https://api.whatsapp.com/send?phone=+918428427755&text=Hi This is Social Eagle."
        class="float"
        target="_blank"
      >
        <i class="fa-brands fa-whatsapp  my-float"></i>
      </a>
      <footer className="section bg-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-7">
              <div className="">
                <div className="mb-3">
                  <img className="logo-footer" src={FooterLogo} alt=""></img>
                </div>
                {/* <p className="contact-info mb-1">Office address :</p> */}
                <p className="contact-info ">
                  No.14,15,16, Global Hospital Signal,<br/> Opp. to ICICI Bank & Above Globus G-World (2nd Floor), <br/> Indira
                  Priyadarshini Nagar,<br/> Perumbakkam, <br/>Chennai - 600100
                </p>
                {/* <p className="contact-info mb-1">Registered address :</p>
                <p className="contact-info ">
                  P305, Oxygen by Urban Tree, Pushkin Street, Gandhi Nagar,
                  Perumbakkam, Chennai – 600100
                </p> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-5">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Company
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <Link to="/" onClick={scrollToTop}>Home </Link>
                  </li>
                  <li>
                    <Link to="/AboutUs" onClick={scrollToTop}>About Us</Link>
                  </li>
                  <li>
                    <Link to="/Blogs" onClick={scrollToTop}>Blogs</Link>
                  </li>
                  <li>
                    <a
                      href="https://g.page/r/CYAUKtAv2HyzEAI/review"
                      target="_blank"
                    >
                      Reviews
                    </a>
                  </li>
                  <li>
                    <Link to="/WorkWithUs" onClick={scrollToTop}>Careers</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs" onClick={scrollToTop}>Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy" onClick={scrollToTop}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/TermsandConditions" onClick={scrollToTop}>Terms & Condition</Link>
                  </li>
                  <li>
                    <Link to="/RefundandCancellationPolicy" onClick={scrollToTop}>Refund & Cancellation Policy</Link>
                  </li>
                  <li>
                    <a href="https://merchant.razorpay.com/policy/N1vv5Esmr1UXYN/shipping" onClick={scrollToTop}>Shipping & Delivery Policy</a>
                  </li>
                  
                </ul>
              </div>
            </div>

            {/* <div className="col-lg-2 col-md-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Services
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <Link to="/Agency" onClick={scrollToTop}>Google Ads</Link>
                  </li>
                  <li>
                    <Link to="/Agency" onClick={scrollToTop}>Facebook Ads</Link>
                  </li>
                  <li>
                    <Link to="/Agency" onClick={scrollToTop}>Landing Pages</Link>
                  </li>
                  <li>
                    <Link to="/Agency" onClick={scrollToTop}>Ads to Appointment</Link>
                  </li>
                  <li>
                    <Link to="/Advisory" onClick={scrollToTop}>Personal Branding</Link>
                  </li>
                  <li>
                    <Link to="/Academy" onClick={scrollToTop}>Digital Marketing Course</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-2 col-md-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Solution
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <Link to="/Agency" onClick={scrollToTop}>Agency</Link>
                  </li>
                  <li>
                    <Link to="/Academy" onClick={scrollToTop}>Academy</Link>
                  </li>
                  <li>
                    <Link to="/Advisory" onClick={scrollToTop}>Advisory </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div>
                <h6 className="footer-heading mb-3 text-uppercase text-white">
                  Want Some Marketing Goodies For Free?
                </h6>
                <p href="#" className="small lh-base mb-0 text-white">
                  Get Marketing Strategies, Insights & News about digital
                  ecosystem sent straight to your inbox.
                </p>
                <form
                  className="my-4 pos-footer-input"
                  onSubmit={(e) => {
                    validate(e);
                  }}
                >
                  <input
                    type="email"
                    onChange={(e) => {
                      setFieldData(e.target.value, "emailId");
                    }}
                    value={data.emailId}
                    placeholder="Enter Your Email"
                    className="form-control footer-input"
                  />
                  <p
                    style={{
                      color: "red",
                      marginTop: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {validation.emailId}
                  </p>

                  <button className="btn mt-4 btn-light footer-btn">
                    {" "}
                    {loading ? (
                      <i className="fa fa-circle-notch fa-solid"></i>
                    ) : (
                      "Send Me"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/SocialEagleOfficial/"
                    target="_blank"
                  >
                    <i className="fab facebook footer-social-icon fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/socialeagle/"
                    target="_blank"
                  >
                    <i class="fa-brands twitter footer-social-icon fa-linkedin-in"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/@socialeagleofficial1155"
                    target="_blank"
                  >
                    <i class="fa-brands youtube footer-social-icon fa-youtube"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/socialeagleofficial/?hl=en"
                    target="_blank"
                  >
                    <i className="fa-brands insta fa-instagram footer-social-icon"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.google.com/maps/dir//No.14-16,+Global,+above+fresh2day,+Hospital+Rd,+Indira+Priyadarshini+Nagar,+Perumbakkam,+Chennai,+Tamil+Nadu+600100/@12.9052002,80.1257523,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a525d14f00995f7:0xb37cd82fd02a1480!2m2!1d80.2081542!2d12.9052131?entry=ttu"
                    target="_blank"
                  >
                    <i class="fa-brands google fa-google footer-social-icon"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <p>
                <a
                  href="mailto:flyhigh@socialeagle.in"
                  className="contact-info text-decoration-none"
                >
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                  <span> flyhigh@socialeagle.in</span>
                </a>
              </p>
            </div>
            <div className="col-lg-3">
              <p className="">
                <a
                  href="tel:+91 8428421222"
                  className="contact-info text-decoration-none"
                >
                  <span>
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  <span> +91 8428421222</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="text-center bg-red mt-5 py-4">
          <p className="footer-alt mb-0 f-14">
            ©2024 Social Eagle Pvt Ltd. All Rights Reserved.
          </p>
        </div>
      </footer>
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
    </div>
  );
};
