import React, { useEffect } from "react";
import { Navbar } from "./Navbar/Navbar";
import { Footer } from "./Footer/Footer";
import { useLocation } from "react-router-dom";

export const RefundPolicy = () => {
    const location = useLocation();
    useEffect(() => {
        const { search } = location;
        if (!search) {
            window.scroll(0, 0);
        }
    }, []);
    return (
        <div>
            <Navbar />
            <div className="wmo mt-1 section-gap">
                <div className="container">

                    <div title="Page 1" style={{ textAlign: "left", marginTop: "100px" }}>
                        <h3>Refund and Cancellation Policy</h3>
                        <ul>
                            <li>Course Fee</li>
                            <ul>
                                <li>The total course fee is INR 50,000/- plus 18% GST</li>
                                <li>To reserve a slot for the upcoming batch, a non-refundable booking amount of INR 10,000/- plus 18% GST must be paid</li>
                                <li>The balance payment for the course must be completed on or before the classes commence</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>Refund Policy</li>
                            <ul>
                                <li>Once the classes have commenced, no refund requests will be entertained</li>
                                <li>However, if a student cancels their course registration before 10 days of the class commencement date, the registration fee of INR 10,000/- will be refunded</li>
                                <li>Please note that the registration fee is the only portion of the course fee that is refundable under any circumstances</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>Cancellation Procedure</li>
                            <ul>
                                <li>To request a cancellation and initiate the refund process for the registration fee, students must notify the course administration team via email or written communication</li>
                                <li>The cancellation request must be received at least 10 days prior to the class commencement date to be eligible for a refund of the registration fee</li>
                                <li>Upon receipt of the cancellation request, the administration team will process the refund within a reasonable timeframe</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>Non-Transferable</li>
                            <ul>
                                <li>Course slots and registrations are non-transferable</li>
                                <li>The booking amount and registration fee are applicable only to the individual who originally booked the slot</li>
                            </ul>
                        </ul>
                        <ul>
                            <li>GST</li>
                            <ul>
                                <li>All refunds will be processed excluding the GST amount paid during the initial booking.</li>
                                <li> Only the principal amount of INR 10,000/- will be refunded</li>
                            </ul>
                        </ul>

                        <ul>
                            <li>Changes to the Policy</li>
                            <ul>
                                <li>The course administration reserves the right to modify or update this refund and cancellation policy at any time without prior notice</li>
                                <li>Any changes made will be effective immediately upon posting the revised policy on the official course website or communication channels</li>
                            </ul>
                        </ul>

                        <ul>
                            <li>Contact Information</li>
                            <ul>
                                <li>For any inquiries regarding refunds or cancellations, please contact us directly at +91 842 842 122</li>
                            </ul>
                        </ul>
                        <p>By booking a slot and paying the registration fee, students acknowledge that they have read, understood, and agree to abide by the terms and conditions outlined in this refund and cancellation policy. For any further inquiries or clarification regarding the policy, please contact the course administration team.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
