import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { AdvisoryBanner } from "../Components/Advisory/AdvisoryBanner";
import { AdvisoryContent } from "../Components/Advisory/AdvisoryContent";
import { Footer } from "../Components/Footer/Footer";

export const Advisory = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <AdvisoryBanner />
      <AdvisoryContent />
      <Footer />
    </div>
  );
};
