import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { AboutContent } from "../Components/AboutUs/AboutContent";
import { AboutBanner } from "../Components/AboutUs/AboutBanner";
import { useLocation } from "react-router-dom";
export const AboutUs = () => {
  const location = useLocation();
  useEffect(() => {
    const { search } = location;
    if (!search) {
      window.scroll(0, 0);
    }
  }, []);
  return (
    <div>
      <Navbar />
      <AboutBanner />
      <AboutContent />
      <Footer />
    </div>
  );
};
