import React, { useState } from "react";
import CustomModal from "../CustomModal";
import BrochureDownloadModal from "./BroucherDownloadModal";

export const AcademyBanner = () => {
  const [open, setOpen] = useState(false);
  
  return (
    <>
      <div className="bg-video-wrap2 academy-banner" data-aos="fade-up" data-aos-delay="150">
        {/* <div className="overlay"></div> */}
        <div className="home-video-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="banner-heading mb-2 text-uppercase">Academy</h1>
                <h1 className="banner-heading fs-2 mb-2 text-uppercase">EMPOWER | ELEVATE | EMBRACE</h1>
                <p className="text-white text-center text-capitalize mb-0">The Limitless Potential of Digital Marketing</p>
                <div className="text-center">
                  <button type="button" className="btn mt-4 btn-primary contact-btn rounded-0" onClick={() => setOpen(true)}>
                    Download Brochure
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <BrochureDownloadModal
        open={open}
        setClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
