import React from "react";

const CaseStudyBanner = () => {
  return (
    <div className="bg-video-wrap2 casestudy-banner" data-aos="fade-up" data-aos-delay="150">
      {/* <div className="overlay"></div> */}
      <div className="home-video-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h1 className="banner-heading">
              Case Study
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyBanner;
