import React from "react";
import BlogImg2 from "../../assets/images/blog-img2.webp";
import { Link } from "react-router-dom";
export const Crorepati = () => {
  return (
    <div>
      <div>
        <div
          className="bg-video-wrap2 blog-details-banner"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="home-video-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <h1 className="banner-heading mb-2 text-uppercase">
                    6 steps to become a Crorepati
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-5 section-gap"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="blog-content ">
                  <div className="row">
                    <div className="col-lg-8">
                      <img src={BlogImg2} className="w-100 h-auto"></img>
                    </div>
                  </div>
                  <div className="d-flex my-3 align-items-center">
                    <span className="me-2">
                      <i class="fa-regular text-secondary fa-calendar-days"></i>
                    </span>
                    <p className="text-secondary mb-0">12 Aug 2022</p>
                  </div>
                  <h2 className="font-bold mb-4">
                  6 Steps to Become a Crorepati
                  </h2>
                  <div className="">
                    <p>Why should one be motivated to work toward being a crorepati?</p>
                    <p>When one considers the current rate of inflation, it is impossible not to speculate about the potential economic collapse that lies ahead of us in the not-too-distant future.</p>
                    <p>Becoming a crorepati is not restricted to a certain group of "smart" people. End the "I can't do it" mentality and focus instead on completing the 6 practical steps outlined below.</p>
                    <p>1. Find the Revenue/Profit level you want to work with Your product's total sales volume and the percentage of those sales that are profit are inversely linked to one another.</p>
                    <p>That is, in order to become a crorepati, you will need to sell a product to a total of one billion customers if the profit from the sale of that commodity is one rupee. Similarly, if your product generates a profit of 10000 rupees, you might only need to sell it to a thousand customers to accomplish what you aimed for.</p>
                    <p>2. Find the Product/Service in that Revenue/Profit Level Pick a good or service that fits the bill. If I were to say, for instance, that I am involved in digital marketing, my typical billing value would fall somewhere in the range of 50,000 to 70,000 rupees.
</p>
                    <p>Similarly, you should be aware of the goods from which zone you will be selling. As soon as we've settled on the region, we can turn our attention to the product and select something that truly excites us. 3. Choose what you love - Ikigai Ikigai is a Japanese term. Finding something you love, doing that thing you love, and making money off of it is a simple way to live your life.</p>
                    <p>3. When you have determined the zone in which you wish to work and located a product that you genuinely enjoy using, selling that product will no longer be an anxious experience for you.</p>
                    <p>4. Learn about the Product/Service The most difficult part of the process is getting your first four customers.</p>
                    <p>After you have established a clientele, you will have the ability to progressively raise your prices while maintaining the same level of quality.</p>
                    <p>Your first few clients are the foundation around which your portfolio will be built.</p>
                    <p>5. Market your Product/Service</p>
                    <p>Today, marketing is very easy. Utilise social media to its full potential while also making an honest effort to provide excellent service to your clientele.</p>
                    <p>6. Deliver Results, Upsell/Cross sell</p>
                    <p>When a customer has reached a level of contentment with the products or services they have purchased from you, it is time to suggest complementary purchases or an upgrade from the product or service they are now using.</p>
                    <p>You also have the option of asking for referrals.</p>
                    <p>Choosing a zone is the first and most crucial step, and I cannot emphasise this point enough. Products with a small profit margin will require more time to develop.</p>
                    <p>If you want to achieve your objective more quickly, you should select a business that has a bigger profit margin.</p>
                    <p>I bid adieu with this thought - Choose wisely, live joyfully!</p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-gap text-center">
          <Link to="/ContactUs">
            <button type="button" className="btn btn-primary contact-btn">
              Get In Touch
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
