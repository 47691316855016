import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { TypesofDM } from "../Components/BlogDetails/TypesofDM";

export const TypesofDigitalMarketing = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <TypesofDM/>
      <Footer />
    </div>
  );
};
