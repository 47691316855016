import React from "react";
import BlogImg15 from "../../assets/images/blog15.webp";
import { Link } from "react-router-dom";

export const DMCA12th = () => {
  return (
    <div>
      <div>
        <div
          className="bg-video-wrap2 blog-details-banner"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="home-video-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <h1 className="banner-heading mb-2 text-uppercase">
                    Digital Marketing Course After 12th
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-5 section-gap"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="blog-content ">
                  <div className="row">
                    <div className="col-lg-8">
                      <img src={BlogImg15} className="w-100 h-auto"></img>
                    </div>
                  </div>
                  <div className="d-flex my-3 align-items-center">
                    <span className="me-2">
                      <i class="fa-regular text-secondary fa-calendar-days"></i>
                    </span>
                    <p className="text-secondary mb-0">June 18, 2023</p>
                  </div>
                  <h2 className="font-bold mb-3">
                    Digital Marketing Course After 12th
                  </h2>
                  <p>
                    In today’s high-tech environment, digital marketing is
                    widely acknowledged as the way to go. As more and more
                    companies shift their focus to online channels in search of
                    customers, those with expertise in digital marketing are in
                    high demand.
                  </p>

                  <p>
                    After completing their secondary education, students
                    interested in entering digital marketing can enroll in one
                    of the many courses available. This post will summarize the
                    most beneficial digital marketing programs available for
                    students after the 12th grade.
                  </p>

                  <div className="">
                    <h3 className="font-bold mb-3">
                      Digital Marketing Course After 12th – Syllabus, Fees, and
                      Duration
                    </h3>
                    <h5 className="font-bold mb-3">Syllabus</h5>
                    <ul>
                      <li>Fundamentals and Objectives of Marketing</li>
                      <li>Social Media Marketing</li>
                      <li>Copywriting</li>
                      <li>Creatives and Designs</li>
                      <li>Video creation methods</li>
                      <li>Artificial Intelligence in Marketing</li>
                      <li>Customer avatar and Business Models</li>
                      <li>Search Engine Optimization</li>
                      <li>Google Ads</li>
                      <li>Facebook Ads (L1)</li>
                      <li>Sales Funnel</li>
                      <li>Facebook Ads (L2)</li>
                      <li>Instagram Usage in Business</li>
                      <li>Influence through LinkedIn</li>
                      <li>Automation in “GOD” Mod</li>
                      <li>Capstone Project</li>
                    </ul>
                    <p>
                      <span className="font-bold">Fees:</span> To learn more
                      about our fee structure, check out our Digital Marketing
                      course package.
                    </p>
                    <p>
                      <span className="font-bold">Contact:</span> +91
                      842-842-1222
                    </p>
                    <p>
                      <span className="font-bold">Address:</span> No.14, 15 &
                      16, Indira Priyadarshini Nagar, Global Hospital Signal,
                      Perumbakkam, Chennai – 600 100
                    </p>
                    <p>
                      <span className="font-bold">Email:</span>{" "}
                      academy@socialeagle.in
                    </p>
                    <p>
                      <span className="font-bold">Duration: </span>1 Month
                    </p>
                    <h3 className="font-bold mb-3">
                      Digital Marketing Courses after 10th
                    </h3>
                    <p>
                      Enrolling in a digital marketing course after 10th will
                      set you on the right career path. You need to learn how to
                      accomplish something and understand how to run a business.
                    </p>
                    <p>
                      The courses educate students on a wide variety of topics,
                      including. The course includes content authoring, search
                      engine optimization (SEO), social media marketing, digital
                      analytics, tools, and affiliated marketing.
                    </p>
                    <p>
                      The digital marketing course is for students interested in
                      digital marketing and experienced people to upgrade their
                      careers.
                    </p>
                    <p>
                      Digital marketing growth made it challenging to be
                      up-to-date on industry development and to find a job.
                      Taking a digital marketing course benefits you to land a
                      digital marketing job in the future.
                    </p>
                    <h3 className="font-bold mb-3">
                      Why Do You Need a Digital Marketing Course After the 12th?
                    </h3>
                    <p>
                      There is no doubt about it; it’s a branch of marketing
                      used to advertise and sell goods online, whether that
                      happens via social networks, email, web-based ads, or
                      blogs.
                    </p>
                    <p>
                      Brands and businesses can reach consumers via these
                      channels. It’s the easiest strategy for getting people to
                      buy your wares.
                    </p>
                    <p>
                      Students frequently use social media sites, like Twitter,
                      Instagram, Facebook, and YouTube. They can promote their
                      own goods and services on these sites.
                    </p>
                    <p>
                      Seems intriguing, right? With the right training,
                      individuals can learn how to sell on social media and
                      other online platforms. For this reason, taking a course
                      in digital marketing after secondary education is always
                      recommended.
                    </p>

                    <h3 className="font-bold mb-3">
                      Why is Digital Marketing the Best Career Opportunity after
                      12th?
                    </h3>
                    <h5 className="font-bold mb-2">
                      Demand for digital marketers is high
                    </h5>
                    <p>
                      In the near future, there will be a continued high need
                      for those who can market goods and services online.
                    </p>
                    <p>
                      This is due to a significant gap between the supply and
                      demand of digital marketing specialists. In addition,
                      businesses are extremely eager to fill digital marketing
                      positions.
                    </p>
                    <p>
                      Companies are aware of the many advantages that the
                      Internet and digital platforms offer. This helps them to
                      scale their firm to a wider extent, produce more cash, and
                      reach a wider audience thanks to digital marketing.
                    </p>
                    <p>
                      Therefore, acquiring marketable skills is never a bad
                      idea. It merely indicates that you will have a greater
                      chance of obtaining employment that pays better and
                      provides a higher level of security this is due to
                      continued rise in demand for talents of this nature.
                    </p>
                    <h5 className="font-bold mb-2">
                      Digital marketing Careers can be very profitable
                    </h5>
                    <p>
                      Digital marketers and the skill sets associated with them
                      are currently among the most in-demand professions around
                      the world.{" "}
                    </p>
                    <p>
                      According to a survey of Google Trend search data, digital
                      marketing abilities place one among the top trending job
                      opportunities.{" "}
                    </p>
                    <p>
                      If you want to raise your income, you should learn digital
                      marketing as soon as possible. Digital Marketing
                      Employment Remuneration is significantly higher than
                      average due to the increased demand for these roles.{" "}
                    </p>
                    <p>
                      Because of your level of knowledge, you may qualify for
                      more responsible positions within the company, which could
                      result in a raise in pay.{" "}
                    </p>
                    <p>
                      Because of the rapid pace at which things change in the
                      digital marketing sector, it is imperative that you always
                      be on your toes and always be ready to learn and unlearn
                      new things if you want to continue to be successful in the
                      area and remain relevant.{" "}
                    </p>
                    <p>
                      You have to be confident that you can command pay
                      commensurate with your ability!
                    </p>
                    <h5 className="font-bold mb-2">
                      Digital marketing can help your career grow quickly{" "}
                    </h5>
                    <p>
                      We disagree with the widespread belief that digital
                      marketing is a dead-end career path. As an illustration of
                      how rapidly the Internet evolves, consider the fact that
                      relatively few people used WhatsApp in 2011.{" "}
                    </p>
                    <p>
                      Marketers in the digital realm need to be flexible in the
                      face of these ongoing shifts. Therefore, they are
                      continually expanding their knowledge and developing their
                      skills.{" "}
                    </p>
                    <p>
                      There is virtually no development ceiling. Digital
                      marketing is a field full of new opportunities and
                      exciting challenges.
                    </p>
                    <h5 className="font-bold mb-2">
                      Digital marketing will continue to exist
                    </h5>
                    <p>
                      Similar to technology, the marketing industry is
                      ever-evolving, thus practitioners must be adaptable when
                      incorporating fresh ideas. In the 1990s, some people
                      believed “the Internet” was only a fad.{" "}
                    </p>
                    <p>
                      We now know that this isn’t true at all. There are no
                      signs that the growth of digital marketing will slow down.
                      The marketing budgets of major multinational companies
                      like Unilever and P&G as well as unicorns like Amazon and
                      Zomato increasingly include a sizable portion of the
                      budget.
                    </p>
                    <p>
                      Consequently, we are here to correct everyone who thinks
                      digital marketing will become obsolete in the future.
                    </p>
                    <h5 className="font-bold mb-2">Flexibility</h5>
                    <p>
                      A career in digital marketing offers a lot of flexibility
                      in terms of employment, making it an appealing option for
                      a number of reasons.{" "}
                    </p>
                    <p>
                      Those who are interested in working while traveling and
                      doing other work according to their own convenient timings
                      may find that a profession in digital marketing could be
                      of great assistance to them.{" "}
                    </p>
                    <p>
                      The hustle required for a profession in digital marketing
                      enables one to hustle in other areas. Most occupations
                      like these have project deadlines, And as a result, one
                      can dedicate the desired number of hours to work whenever
                      they choose during the day.{" "}
                    </p>
                    <p>
                      Even digital marketers working for digital marketing
                      organizations have the option to perform their duties from
                      the comfort of their own homes, offices, or other
                      locations of their choosing.
                    </p>
                    <p>
                      If one has a strong interest in digital marketing,
                      pursuing a career in the field is likely to be a very easy
                      and stress-free experience for them.
                    </p>
                    <p>
                      The ideal vocation and an alternative for establishing a
                      career out of digital marketing is because of the
                      flexibility it offers in working hours and the working
                      environment.
                    </p>
                    <h3 className="font-bold mb-3">
                      Digital Marketing Salary in India
                    </h3>
                    <p>
                      Even for beginners, digital marketing companies in India
                      offer satisfactory compensation. A significant increase in
                      your potential earnings awaits you if, in addition, you
                      have any prior experience in digital marketing.
                    </p>
                    <p>
                      The starting compensation for a digital marketing position
                      is rupees 6.5lakhs per year However, it can go anywhere
                      from rupees 4.5 to 5 lakhs per year.
                    </p>
                    <p>
                      The annual income might range anywhere between 12 and 20
                      lakh Indian rupees if you have 5 to 10 years of experience
                      working in this industry.
                    </p>
                    <p>
                      You can make between 20 and 30 lakhs per year if you have
                      more than ten years of expertise in the field.
                    </p>
                    <h2 className="font-bold mb-3">Conclusion</h2>
                    <p>
                      I hope that the digital marketing course you take
                      following your 12th-grade education will be enjoyable and
                      infomative and that you choose the right career path for
                      you.
                    </p>
                    <p>
                      The world we live in today requires businesses to have a
                      strong presence in digital marketing. Taking the digital
                      marketing course after you secondary education will
                      provide you with the opportunity to reap the benefits of
                      being an early entrant.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-gap text-center">
          <Link to="/ContactUs">
            <button type="button" className="btn btn-primary contact-btn">
              Get In Touch
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
