import React, { useState } from "react";
import Join1 from "../../assets/images/join1.webp";
import Join2 from "../../assets/images/join2.webp";
import Join3 from "../../assets/images/join3.webp";
import L1 from "../../assets/images/l1.webp";
import FB from "../../assets/images/fb.webp";
import Insta from "../../assets/images/insta.webp";
import LinkedIn from "../../assets/images/linkedin.webp";
import AC1 from "../../assets/images/ac1.webp";
import AC2 from "../../assets/images/ac2.webp";
import AC3 from "../../assets/images/ac3.webp";
import AC4 from "../../assets/images/ac4.webp";
import AC5 from "../../assets/images/ac5.webp";
import E1 from "../../assets/images/l1.webp";
import E2 from "../../assets/images/l2.webp";
import E3 from "../../assets/images/l3.webp";
import E4 from "../../assets/images/e4.webp";
import E5 from "../../assets/images/e5.webp";
import E6 from "../../assets/images/e6.webp";
import E7 from "../../assets/images/e7.webp";
import E8 from "../../assets/images/e8.webp";
import E9 from "../../assets/images/e9.webp";
import E10 from "../../assets/images/e10.webp";
import E11 from "../../assets/images/e11.webp";
import E12 from "../../assets/images/e12.webp";
import E13 from "../../assets/images/e13.webp";
import E14 from "../../assets/images/e14.webp";
import E15 from "../../assets/images/e15.webp";
import E16 from "../../assets/images/e16.webp";
import E17 from "../../assets/images/l4.webp";
import Z1 from "../../assets/images/z1.webp";
import Z2 from "../../assets/images/z2.webp";
import Z3 from "../../assets/images/z3.webp";
import Z4 from "../../assets/images/z4.webp";
import Z5 from "../../assets/images/z5.webp";
import Z21 from "../../assets/images/z21.webp";
import Z22 from "../../assets/images/z22.webp";
import Z23 from "../../assets/images/z23.webp";
import Z24 from "../../assets/images/z24.webp";
import Z31 from "../../assets/images/z31.webp";
import Z32 from "../../assets/images/z32.webp";
import Z33 from "../../assets/images/z33.webp";
import Z34 from "../../assets/images/z34.webp";
import Z35 from "../../assets/images/z35.webp";
import Z41 from "../../assets/images/z41.webp";
import Z42 from "../../assets/images/z42.webp";
import Z43 from "../../assets/images/z43.webp";
import Z44 from "../../assets/images/z44.webp";
import Z51 from "../../assets/images/z51.webp";
import Z52 from "../../assets/images/z52.webp";
import Z53 from "../../assets/images/z53.webp";
import Z54 from "../../assets/images/z54.webp";
import Z55 from "../../assets/images/z55.webp";
import Z61 from "../../assets/images/z61.webp";
import Z62 from "../../assets/images/z62.webp";
import Z63 from "../../assets/images/z63.webp";
import Z64 from "../../assets/images/z64.webp";
import Z71 from "../../assets/images/z71.webp";
import Z72 from "../../assets/images/z72.webp";
import Z73 from "../../assets/images/z73.webp";
import Z74 from "../../assets/images/z74.webp";
import Z75 from "../../assets/images/z75.webp";
import E18 from "../../assets/images/e18.webp";
import Logo from "../../assets/images/logo.webp";
import DMM1 from "../../assets/images/dmm1.webp";
import DMM2 from "../../assets/images/dmm2.webp";
import DMM3 from "../../assets/images/dmm3.webp";
import DMM4 from "../../assets/images/dmm4.webp";
import DMM5 from "../../assets/images/dmm5.webp";
import DMM6 from "../../assets/images/dmm6.webp";
import DMM7 from "../../assets/images/dmm7.webp";
import DMM8 from "../../assets/images/dmm8.webp";
import DMM9 from "../../assets/images/dmm9.webp";
import DMM10 from "../../assets/images/dmm10.webp";
import AcadFullImg from "../../assets/images/acad-full-img.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import GraphAcademy1 from "../../assets/images/graph-academy1.webp";
import GraphAcademy2 from "../../assets/images/graph-academy-2.webp";
import MapAcademy from "../../assets/images/map-academy.webp";
import AcademyModal from "./AcademyModal";
export const AcademyContent = () => {
  const [open, setOpen] = useState();
  const testimonial = [
    {
      heading: "Anitha Swaminathan",
      paraheading: "GROWTH Beyond the Course...",
      rating: 5,
      para: "I underwent the Digital Entrepreneurship Initiator Program. Its not just the course. it changes complete mindset. Beyond from the course content, there is always a connection with the Team which always push us from our mind to proceed further without lag. There is a wonderful community to support and collaborate.They are providing more and more values. Thanks to Dharanee bro & team for the wonderful community and program",
    },
    {
      heading: "Siva Pragasam",
      paraheading: "I started this course without knowing...",
      rating: 5,
      para: "I started this course without knowing anything about Digital Marketing course. I learnt everything within one month time including variety of opportunities and AI tools. Within one month time I got so many ideas & gain confidence even I can agency. Overall excellent courses. Thanks a lot to Social Eagle.",
    },

    {
      heading: "Krishna Prasanth",
      paraheading: "Social Eagle community gave me the best…",
      rating: 5,
      para: "Social Eagle community gave me the best experience during the learning process and gave me the confidence to make or start our own Digital Marketing agency. They are more friendly and supportive. It's a great community to learn Digital Marketing. Don't miss the chance",
    },
    {
      heading: "Gowri Bhuvana",
      paraheading: "I gained value more than i expected",
      rating: 5,
      para: "I gained value more than i expected. Lots of value, guidance, coordination. Doubt clear session was very useful. With the help of this Digital Marketing course I am earning more than I expected. Thank you so much.",
    },
    {
      heading: "Karthika Ponnuchamy",
      paraheading: "The Social Eagle journey had a big…",
      rating: 5,
      para: "The Social Eagle journey had a big impact on my life and career, starting with mindset and leading to business growth and Digital Marketing. If you're looking for a great Digital Marketing course in Tamil Nadu, they're a trustworthy team that gives 200% in training.",
    },
    {
      heading: "Magalingam",
      paraheading: "Digital Marketing course",
      rating: 5,
      para: "I am a newcomer to the Digital Marketing course, and so far, the content on social media, lead generation, and automation has been incredibly valuable. Learning to create landing pages has been particularly useful for me. The practical insights provided are helping me build a strong foundation in digital marketing. Looking forward to exploring more in the upcoming modules.",
    },
    {
      heading: "Nithyanandan S",
      paraheading: "Fantastic persons to learn Digital…",
      rating: 5,
      para: "Fantastic persons to learn Digital Marketing, from basics of marketing to advanced. Worth for it. Go.",
    },
  ];
  return (
    <div>
      <div
        className="wmo mt-5 section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 mb-4">
              <h2 className="section-heading text-center">
                Become{" "}
                <span className="color-sec-head">
                  The Top 1% Professional <br /> Digital Marketer
                </span>
              </h2>
              <p className="para-color res-font text-center">
                We at Social Eagle Academy, take great pride in offering India’s
                premier Digital Marketing course in Chennai.
              </p>
              <p className="para-color res-font text-center">
                We’re passionate about giving you the skills you need to succeed
                in the world of Digital Marketing.
              </p>
              <p className="para-color res-font text-center">
                We have both online and offline sessions which are
                comprehensive, hands-on, and designed to <br /> guide you every
                step of the way.
              </p>
              <p className="para-color res-font text-center">
                Join our Community of Experts today and unlock your full
                potential as a Digital Marketer and Scale up your growth by 10X!
              </p>
            </div>
            <div className="col-lg-9 mb-4">
              <div className="business-card border-right-red">
                <h5 className="section-heading text-center mb-0 lh-base  fs-5">
                  <span className="color-sec-head">Tamil nadu's</span> only
                  Digital Marketing training{" "}
                  <span className="text-lowercase font-bold"> which is</span>{" "}
                  <span className="color-sec-head">
                    functional, AI Powered,
                  </span>{" "}
                  100%{" "}
                  <span className="color-sec-head">
                    Hands-On, Agency based &
                  </span>{" "}
                  supported by <span className="color-sec-head">who's who</span>{" "}
                  of the industry.
                </h5>
              </div>
            </div>
            <div className="col-lg-9 mb-4">
              <div className="join-now-bg">
                <div className="w-100">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-10">
                      <div className="row mb-lg-4 mb-2 align-items-center">
                        <div className="col-2">
                          <img
                            className="w-100 h-auto"
                            src={Join1}
                            alt=""
                          ></img>
                        </div>
                        <div className="col-5 px-0">
                          <h6 className="mb-0">Next Cohort </h6>
                        </div>
                        {/* <div className="col-1"></div> */}
                        <div className="col-5 px-0">
                          <h6 className="font-bold mb-0">- December 2024 </h6>
                        </div>
                      </div>
                      <div className="row mb-lg-4 mb-2 align-items-center">
                        <div className="col-2">
                          <img
                            className="w-100 h-auto"
                            src={Join2}
                            alt=""
                          ></img>
                        </div>
                        <div className="col-5 px-0">
                          <h6 className="mb-0 ">Course Duration </h6>
                        </div>
                        {/* <div className="col-1"></div> */}
                        <div className="col-5 px-0">
                          <h6 className="font-bold mb-0">- 1 Month </h6>
                        </div>
                      </div>
                      <div className="row mb-lg-4 mb-2 align-items-center">
                        <div className="col-2">
                          <img
                            className="w-100 h-auto"
                            src={Join3}
                            alt=""
                          ></img>
                        </div>
                        <div className="col-5  px-0">
                          <h6 className="mb-0">Course Modules</h6>
                        </div>
                        {/* <div className="col-1"></div> */}
                        <div className="col-5 px-0">
                          <h6 className="font-bold mb-0">- 21 Modules</h6>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          onClick={() => {
                            setOpen(true);
                          }}
                          type="button"
                          className="btn mb-3 btn-primary contact-btn "
                        >
                          Join Now
                        </button>
                      </div>
                      <div>
                        <p className="small text-center mb-0">
                          (Only Limited Students per Cohort)
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-5 academy-leader">
              <h2 className="section-heading text-center">
                Prime <span className="color-sec-head">Faculties</span>
              </h2>
              <div className="row">
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto img-top-team  rounded-top"
                      src={E1}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Dharaneetharan G D</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Founder & <br /> Thought Leader
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/dharaneetharangd/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>

                        <a
                          href="https://www.instagram.com/dharaneetharan/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/dharaneetharan/?originalSubdomain=in"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto img-top-team  rounded-top"
                      src={E2}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Vishnu Hari</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Performance Marketer & <br /> Lead Trainer
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/SocialEagleOfficial/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/imvishnuhari/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/vishnuhari-harikumar/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto img-top-team  rounded-top"
                      src={E3}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Sathish S</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Sales & <br /> Acquisition Expert
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/SocialEagleOfficial/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/socialeagleofficial/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/socialeagle/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E18}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Shatheeshram G</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Sales Beast <br /> &nbsp;
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/shatheeshram?mibextid=ZbWKwL"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/shatheeshramganapathy?igsh=MW5nYzc4Nmh3OXJvdQ=="
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/shatheeshram-ganapathy-a7476473/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 academy-leader mb-5">
              <h2 className="section-heading text-center">
                Expert <span className="color-sec-head">Sessions</span>
              </h2>
              <div className="row">
                <div className="col-lg-3 col-md-4">
                  {" "}
                  <div className="case-study-card">
                    {" "}
                    <img
                      className="w-100 h-auto img-top-team  rounded-top"
                      src={E17}
                      alt=""
                    ></img>{" "}
                    <div className="text-center pt-3">
                      {" "}
                      <h6 className="font-bold mb-2">
                        Priya Dharaneetharan
                      </h6>{" "}
                      <p className="mb-0 x-small-para text-secondary">
                        {" "}
                        Personal Branding & <br /> Authority Creator{" "}
                      </p>{" "}
                      <div className="d-flex justify-content-center mt-2">
                        {" "}
                        <a
                          href="https://www.facebook.com/priya.dharani.52?mibextid=YMEMSu"
                          target="_blank"
                        >
                          {" "}
                          <img
                            className="fb-insta-icon"
                            src={FB}
                            alt=""
                          ></img>{" "}
                        </a>{" "}
                        <a
                          href="https://www.instagram.com/priyadharaneetharan?igsh=eTg0NHJnMDQwZWxz"
                          target="_blank"
                        >
                          {" "}
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/priyadharani?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                          target="_blank"
                        >
                          {" "}
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>{" "}
                        </a>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E4}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Sudharsanan Ganapathy</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Founder & CEO - The Social Company <br /> Personal
                        Branding Expert
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/sudharinbox/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/sudharsananganapathy/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/sudharsananganapathy/?originalSubdomain=in"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E6}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Jameluddin</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Founder - Funnel God <br />7 Figure Funnel Expert
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/FunnelGod"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/socialeagleofficial/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/jamepreneur/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E7}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Azaruddin</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Co-founder - Propreneur <br /> Marketing Experimenter
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/azaruddindotcom"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/socialeagleofficial/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/azazar/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 academy-leader mb-5">
              <h2 className="section-heading text-center">
                Special <span className="color-sec-head">Lectures</span>
              </h2>
              <div className="row">
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E8}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Suresh Radhakrishnan</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Entrepreneur <br /> Business Coach & Creator
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/sureshradhakrishnansrk/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/sureshradhakrishnansrk/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/sureshradhakrishnansrk/?originalSubdomain=in"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E9}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Sakthivel Pannerselvam</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        CEO - the6.in <br />
                        Guerilla Marketer
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/sakthivel.pannerselvam.surprise/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/sakthivelpannerselvam6/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/sakthivelpannerselvam/?originalSubdomain=in"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E10}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Ram Kumar</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        MD - Chennai Memes <br />
                        Digital Disruption Expert
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/SocialEagleOfficial/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/its_ramkumarrs/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/ramkumar-rs/?originalSubdomain=in"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                */}
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E11}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Sakthi Ektha</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Head of Content - The Social Company <br /> Content &
                        Growth Strategist
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/SocialEagleOfficial/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/sakthiektha/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/sakthiektha/?originalSubdomain=in"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E12}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Kennet Alphy</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Founder & CEO - Brandocx <br /> Career Transformation
                        Expert
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/SocialEagleOfficial/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/kennet_alphy/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/kennet-alphy-c/?originalSubdomain=in"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E13}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Rajkumar</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Founder - Neidhal.com <br />
                        E-commerce & Organic Marketing Expert
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/SocialEagleOfficial/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/neidhalrajkumar/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/socialeagle/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E14}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Prabath Krishnan</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Founder - Reels & Shorts <br /> Video Story Teller
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/profile.php?id=100090894013051"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/prabathkrishnan/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/socialeagle/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E15}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Hannah Cremona</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Business Strategist & Mindset <br /> Coach
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://m.facebook.com/hannahcremona.coach/"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/hancremona/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/in/hannahcremona/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-lg-3 col-md-4">
                  <div className="case-study-card">
                    <img
                      className="w-100 h-auto fac-img rounded-top"
                      src={E16}
                      alt=""
                    ></img>
                    <div className="text-center pt-3">
                      <h6 className="font-bold mb-2">Bryan Mills</h6>
                      <p className="mb-0 x-small-para text-secondary">
                        Founder - Passive Buddies <br />
                        Entrepreneur & Affiliate Marketing Expert
                      </p>
                      <div className="d-flex justify-content-center mt-2">
                        <a
                          href="https://www.facebook.com/bryan.mills.779"
                          target="_blank"
                        >
                          <img className="fb-insta-icon" src={FB} alt=""></img>
                        </a>
                        <a
                          href="https://www.instagram.com/socialeagleofficial/?hl=en"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={Insta}
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/socialeagle/"
                          target="_blank"
                        >
                          <img
                            className="fb-insta-icon"
                            src={LinkedIn}
                            alt=""
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-12 ">
              <h2 className="section-heading text-center">
                Who can Choose <br />
                <span className="color-sec-head">
                  the Digital entrepreneurship Initiator{" "}
                </span>
              </h2>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="whocanchoose-card  position-relative p-lg-5 p-3">
                    <div className="academycard-img">
                      <img className="acd-img-icon" src={AC1} alt=""></img>
                    </div>
                    <div className="text-center pt-3">
                      <h5 className="font-extrabold mb-2">STUDENTS</h5>
                      <ul className="ps-3">
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Industry-Leading Expertise:
                            </span>{" "}
                            <br />
                            Learn from Industry Experts to gain a competitive
                            edge in the Digital Marketing domain.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Intensive Program:
                            </span>
                            <br />
                            Get practical exposure through our Digital{" "}
                            Entrepreneurship Initiator Course and kickstart your
                            career with confidence.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Flexible Timings:{" "}
                            </span>{" "}
                            <br />
                            We offer flexible class schedules to accommodate
                            your academic commitments.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="whocanchoose-card  position-relative p-lg-5 p-3">
                    <div className="academycard-img">
                      <img className="acd-img-icon" src={AC2} alt=""></img>
                    </div>
                    <div className="text-center pt-3">
                      <h5 className="font-extrabold mb-2">HOME-MAKERS</h5>
                      <ul className="ps-3">
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Work-Life Balance:
                            </span>{" "}
                            <br />
                            Our part-time courses enable homemakers to enhance
                            their skills without compromising family
                            responsibilities.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Remote Learning:
                            </span>
                            <br />
                            Study conveniently from home with our online Digital
                            Marketing Course.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Freelancing Options:
                              <br />
                            </span>{" "}
                            Explore Freelance Opportunities and earn from the
                            comfort of your home.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="whocanchoose-card  position-relative p-lg-5 p-3">
                    <div className="academycard-img">
                      <img className="acd-img-icon" src={AC1} alt=""></img>
                    </div>
                    <div className="text-center pt-3">
                      <h5 className="font-extrabold mb-2">
                        RETIRED INDIVIDUALS
                      </h5>
                      <ul className="ps-3">
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Stay Mentally Active:
                            </span>{" "}
                            <br />
                            Engage in continuous learning, upskill, and stay
                            mentally graceful after retirement.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Make Money From Your Passion: <br />
                            </span>
                            Turn your hobbies into profitable ventures using
                            Digital Marketing techniques.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Flexible Learning Environment: <br />
                            </span>{" "}
                            Enjoy a stress-free learning experience with our
                            supportive faculty.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="whocanchoose-card  position-relative p-lg-5 p-3">
                    <div className="academycard-img">
                      <img className="acd-img-icon" src={AC4} alt=""></img>
                    </div>
                    <div className="text-center pt-3">
                      <h5 className="font-extrabold mb-2">CAREER TRANSITION</h5>
                      <ul className="ps-3">
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Fast-Track Training:
                            </span>{" "}
                            <br />
                            Gain the essential skills quickly and change to a
                            profitable career in Digital Marketing.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Diverse Career Options:
                            </span>
                            <br />
                            With Digital Marketing skills, explore opportunities
                            across various industries.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="whocanchoose-card  position-relative p-lg-5 p-3">
                    <div className="academycard-img">
                      <img className="acd-img-icon" src={AC5} alt=""></img>
                    </div>
                    <div className="text-center pt-3">
                      <h5 className="font-extrabold mb-2">
                        ABUNDANT OPPORTUNITIES
                      </h5>
                      <ul className="ps-3">
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Entrepreneurial Pursuits: <br />
                            </span>{" "}
                            Become a Digital Marketing expert and grow your own
                            successful online ventures.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              High Salaries:{" "}
                            </span>
                            <br />
                            Enjoy attractive salary packages as Digital
                            Marketing professionals are in high demand.
                          </p>
                        </li>
                        <li>
                          <p className="small text-start">
                            <span className="font-bold color-sec-head">
                              Global Reach: <br />
                            </span>
                            Master Digital Marketing and work with clients
                            worldwide, expanding your horizons.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  type="button"
                  className="btn mb-3 mt-lg-0 mt-4 btn-primary contact-btn "
                >
                  Apply Now
                </button>
                <h5 className="font-bold  fst-italic font-small-mob text-center">
                  We only work with selected individuals. Apply to see if you
                  are eligible
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="company-vibe academy-scope-img"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container my-5">
          <div className="text-center">
            <h1 className="section-heading text-white mb-4">
              Scope of Digital Marketing
            </h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="">
                <p className="text-white text-center lh-base mb-0">
                  There are 4.8 billion social media users so far and it's
                  estimated to reach 5.17 billion by 2024. The projected digital
                  ad spend is to reach $220 billion. There will be a 38%
                  increase in job opportunities for Digital Marketing
                  Professionals by 2028.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="bg-grey-card">
                <h4 className="text-center font-bold text-capitalize">
                  Internet users in <br /> India
                </h4>
                <p className=" text-center">
                  As of January 2021, there <br /> were 624.0 million <br />{" "}
                  internet users in India.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bg-grey-card">
                <h4 className="text-center font-bold text-capitalize">
                  Mobile internet <br /> access
                </h4>
                <p className=" text-center">
                  Over 80% of internet users in India access the internet
                  through <br /> mobile devices.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bg-grey-card">
                <h4 className="text-center font-bold text-capitalize">
                  E-commerce market <br /> projection
                </h4>
                <p className=" text-center">
                  The e-commerce market in India is projected to reach $200
                  billion <br /> by 2026.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="founder-section pt-5 section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container pt-3">
          <div>
            <h2 className="section-heading text-center ">
              It is easy to Enrol{" "}
              <span className="text-lowercase font-bold">in our</span> <br />
              <span className="color-sec-head">Digital Marketing Course </span>
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6">
              <div className="dimg-container  position-relative">
                <img src={MapAcademy} className="w-100 h-auto" alt=""></img>
                <div className="acad-pos">
                  <img className="logo-img" src={Logo} alt=""></img>
                  <p className="small mt-3 mb-2">We Are</p>
                  <h5 className="mb-2">Located</h5>
                  <h4 className="font-bold">In Chennai</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="">
                <ul>
                  <li>
                    <p className="founder-para">
                      Find the Enrolment Form in our website
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Fill in your details to get started.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Make a secure payment using various payment options.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Receive a confirmation email with course details.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Access your course materials and begin learning right
                      away.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Engage with our expert instructors and join interactive
                      sessions.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Complete the course and earn a valuable certification.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Utilize your Digital Marketing skills for career growth.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Start your journey towards success today!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-center">
              <button
                onClick={() => {
                  setOpen(true);
                }}
                type="button"
                className="btn mb-3 btn-primary contact-btn "
              >
                Enrol Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="founder-section discover-section pt-lg-3 pt-0 section-gap">
        <div className="container pt-3">
          <div>
            <h2 className="section-heading text-center mb-5">
              <span className="color-sec-head">Discover the top features </span>
              of our <br /> Digital Marketing Course in Chennai
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM1} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">
                    Gain expertise in all aspects of Digital Marketing
                  </h6>
                  <hr />
                  <p className="founder-para small ps-2 mb-0">
                    Everything you need to learn about Digital Marketing
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center  mb-4">
                <img className="dicover-icon-img" src={DMM2} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Practical Training </h6>
                  <hr />
                  <p className="founder-para small ps-2 mb-0">
                    Hands-on experience with real-world projects.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM3} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Industry Experts </h6>
                  <hr />
                  <p className="founder-para small ps-2 mb-0">
                    Learn from seasoned professionals in their field.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM4} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">
                    Unique Blended Learning{" "}
                  </h6>
                  <hr />
                  <p className="founder-para small mb-0 ps-2">
                    We have a Hybrid learning Approach.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM5} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Updated Content </h6>
                  <hr />
                  <p className="founder-para small mb-0 ps-2">
                    Stay current with the latest industry trends.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM6} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Certification </h6>
                  <hr />
                  <p className="founder-para small ps-2 mb-0">
                    Receive course completion certificate upon completion.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM7} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Power of Community</h6>
                  <hr />
                  <p className="founder-para small ps-2 mb-0">
                    Networking with Like-Minded Individuals.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM8} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Affordable Fees </h6>
                  <hr />
                  <p className="founder-para small ps-2 mb-0">
                    Budget-friendly course with payment options.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM10} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Trusted by the People</h6>
                  <hr />
                  <p className="founder-para small mb-0 ps-2">
                    Trusted by leading Industry Professionals
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img className="dicover-icon-img" src={DMM9} alt=""></img>
                <div>
                  <h6 className="font-bold mb-0 ps-2">Start Today </h6>
                  <hr />
                  <p className="founder-para small mb-0 ps-2">
                    Begin your Digital Marketing journey right away!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="founder-section check-out-page pt-lg-3 pt-0 ">
        <div className="container-fluid pt-3">
          <div>
            <h2 className="section-heading text-center ">
              <span className="color-sec-head">Check Out What </span> Our
              Students Say!
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <OwlCarousel
                className="owl-theme"
                loop
                autoplay={true}
                items="1"
                dots={true}
                smartSpeed={2000}
                nav={false}
                margin={20}
                // center={true}
                autoplayTimeout={5000}
                responsive={{
                  360: {
                    items: "1",
                  },
                  414: {
                    items: "1",
                  },
                  670: {
                    items: "2",
                  },
                  992: {
                    items: "2",
                  },
                  1200: {
                    items: "3",
                  },
                }}
              >
                {testimonial.map((item, index) => {
                  return (
                    <div className="business-card p-3" key={index}>
                      <div>
                        <h6 className="font-bold heading-hightacad">
                          {item.paraheading}
                        </h6>
                        <p className="casestudy-para mb-0">{item.para}</p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h6 className="font-bold mb-0">{item.heading}</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          {new Array(item.rating).fill(null).map((_, i) => (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 28 28"
                              fill="none"
                              key={i}
                            >
                              <path
                                d="M13.9987 20.1484L18.8404 23.0767C19.7271 23.6134 20.8121 22.8201 20.5787 21.8167L19.2954 16.3101L23.5771 12.6001C24.3587 11.9234 23.9387 10.6401 22.9121 10.5584L17.2771 10.0801L15.0721 4.87672C14.6754 3.93172 13.3221 3.93172 12.9254 4.87672L10.7204 10.0684L5.08541 10.5467C4.05874 10.6284 3.63874 11.9117 4.42041 12.5884L8.70208 16.2984L7.41874 21.8051C7.18541 22.8084 8.27041 23.6017 9.15708 23.0651L13.9987 20.1484Z"
                                fill="#FFA41B"
                              />
                            </svg>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
              <div className="text-center">
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  type="button"
                  className="btn my-3 btn-primary contact-btn "
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="founder-section section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container pt-3">
          <div>
            <h2 className="section-heading text-center ">
              <span className="color-sec-head">Growth</span> of Digital
              Marketing
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="dimg-container">
                <img src={GraphAcademy1} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={GraphAcademy2} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="founder-section section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container">
          <div>
            <h2 className="section-heading fs-1 text-center ">
              Join our Digital Marketing Course Certification <br /> for{" "}
              <span className="color-sec-head">
                excellent instruction{" "}
                <span className="font-bold text-dark text-lowercase">and </span>
                knowledge
              </span>
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7 my-auto">
              <div className="">
                <ul>
                  <li>
                    <p className="founder-para">
                      Learn about the business and work on real-world projects.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      You'll get a certification after you finish the course.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Learn from industry pros while staying on top of the
                      latest trends.
                    </p>
                  </li>
                  {/* <li>
                    <p className="founder-para">
                      You can take classes online or in person.
                    </p>
                  </li> */}
                  <li>
                    <p className="founder-para">
                      Take part in a lively community of Digital Marketers.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      New doors are waiting for you if you've got high-demand
                      Digital Marketing skills.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Course rates are reasonable, with flexible payment
                      options.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Make the first step towards a successful Digital Marketing
                      career!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="founder-section section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container-fluid pt-3">
          <div>
            <h2 className="section-heading text-center ">
              <span className="color-sec-head">We take</span> Students Through a
              Journey
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="dimg-container">
                <img src={AcadFullImg} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-5">
        <button
          onClick={() => {
            setOpen(true);
          }}
          type="button"
          className="btn mt-5 btn-primary contact-btn"
        >
          Apply Now
        </button>
      </div>
      <div className="wmo section-gap " data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h2 className="section-heading mb-5 text-center">
                <span className="color-sec-head ">Syllabus </span> of Our
                Digital <br /> entrepreneurship Initiator Program
              </h2>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="150" className="">
            {" "}
            <div className="accordion" id="accordionExample">
              {" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingOne">
                  {" "}
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {" "}
                    Magic is in the Mindset - The Journey Begins Here{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn how to have the right mindset as an
                      Entrepreneur and as a Marketer. You will be able to
                      understand the opportunities in the market and things you
                      need to do to grab those.{" "}
                    </p>{" "}
                    <p className="mb-2">
                      {" "}
                      This Module covers everything from ‘Why you should learn
                      digital marketing’ till ‘How to succeed using this skill’.{" "}
                    </p>{" "}
                    <p className="mb-1">Including sections like,</p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Rich Mindset</li> <li> Ways to Earn</li>{" "}
                        <li> Belief System</li> <li> How to Attract Money</li>{" "}
                        <li>16000+ people mentored</li>{" "}
                        <li>Zone in Business and many more</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingTwo">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {" "}
                    Fundamentals of Marketing{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn how to set everything up to get
                      started.{" "}
                    </p>{" "}
                    <p className="mb-2">
                      {" "}
                      The most comprehensive module on understanding the
                      Fundamentals of Marketing.{" "}
                    </p>{" "}
                    <p className="mb-2">
                      {" "}
                      These are more than just modules with straightforward,
                      shallow YouTube-level content.{" "}
                    </p>{" "}
                    <p className="mb-2">
                      {" "}
                      Each one goes into depth on everything you need to do.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Fundamentals of Marketing</li>{" "}
                        <li>Different types of Marketing</li>{" "}
                        <li> The Internet Marketing Tree</li>{" "}
                        <li>Objectives</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingThree">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {" "}
                    Customer Avatar and their Journey{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn how to define a buyer persona on the
                      internet to target them and how to understand customer
                      behaviour in order to target them effectively.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>
                          Customer Avatar & Understanding the Customers
                        </li>{" "}
                        <li>Psychology Behind Buying Mindset - Journey</li>{" "}
                        <li>How to Capture Large Market</li>{" "}
                        <li>Map of Customer Journey</li>{" "}
                        <li>Understanding the Market Temperature</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingFour">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    {" "}
                    Becoming Better at Design & Creatives{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      This Module will give a deeper understanding on how
                      important Creatives are and how Design skills can make you
                      better in Digital Marketing.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Why Graphic Design is Important</li>{" "}
                        <li>The ‘How & Who’</li>{" "}
                        <li>Branding, Logo & Creative sizes</li>{" "}
                        <li>Creating a Logo - Step by Step</li>{" "}
                        <li>Creating Brochures and Presentations</li>{" "}
                        <li>Designing without a Template (From Scratch)</li>{" "}
                        <li>AI Creatives in Canva</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingFive">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    {" "}
                    Becoming better at Copywriting{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      This Module will teach you how to literally print money
                      through the ‘Art of Writing Text’ that makes people take
                      action.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>What is Copywriting?</li>{" "}
                        <li>Why Copywriting is important for Marketing</li>{" "}
                        <li>Copywriting Principles</li>{" "}
                        <li>Copywriting Frameworks</li>{" "}
                        <li>Types of Copywriting</li>{" "}
                        <li>How to write better Headlines</li>{" "}
                        <li>Effective Story Framework</li>{" "}
                        <li>How to Make Money using Copywriting Skills</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingSix">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    {" "}
                    Video Editing{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn the Art of Visual Storytelling using
                      basic to advanced Video Editing Frameworks that actually
                      work. This is one of the Most In-Demand skills in the
                      current world.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Introduction to Video Editing</li>{" "}
                        <li>Tools & Techniques</li>{" "}
                        <li>Video Creation Techniques</li>{" "}
                        <li>Basics and 8 Steps in Video Editing</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingSeven">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    {" "}
                    Mastering Meta Ads (Level 1){" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Learn ‘Meta’ as a platform and how to effectively
                      advertise using Meta Platforms.This Module includes
                      everything from ‘Starting a Profile’ till ‘Launching your
                      Ad Campaigns’.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Facebook Profile and Pages</li>{" "}
                        <li>Best Practices</li>{" "}
                        <li>Introduction to Meta Ad Manager</li>{" "}
                        <li>Ad account Creation and SOP</li>{" "}
                        <li>Understanding Different Facebook Campaigns</li>{" "}
                        <li>L-1 Lead Generation</li>{" "}
                        <li>Copy, Creative and CTA</li>{" "}
                        <li>Launch your First Ad</li>{" "}
                        <li>Frequent issues in Ads</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingEight">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    {" "}
                    Automation in ‘GOD’ Mode{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn how to make life simpler and
                      efficient using Automations and on-the-go flows to
                      immediately increase your productivity and results.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>What is Automation?</li>{" "}
                        <li>Automation using Privyr</li>{" "}
                        <li>Automation using Zapier</li>{" "}
                        <li>Instagram Comment Automation</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingNine">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    {" "}
                    Sales, Saleees & Saleeeeees{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn the ‘Art of Ethical Selling’, where
                      skill sets such as ‘Effective Selling’ and <br />{" "}
                      ‘Persuasive Communication’ will be taught. Learning this
                      one skill along with the Digital Marketing <br />{" "}
                      Strategies will help you land high-ticket clients
                      effortlessly.{" "}
                    </p>{" "}
                    <p className="mb-1">
                      {" "}
                      A Complete Module on Sales. Everything from start to end.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>What is Selling</li>{" "}
                        <li>Effective Selling + Opportunities</li>{" "}
                        <li>Understanding Business, People, & Leads</li>{" "}
                        <li>Crafting the Sales Presentation</li>{" "}
                        <li>Sales Process - Before, During & After the Call</li>{" "}
                        <li>RASNA Framework</li> <li>Sales CRM</li>{" "}
                        <li>Do and Don’ts</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingTen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    {" "}
                    Funnels (Level 1){" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      This Module is a game changer in terms of Digital
                      Marketing. This will teach you about Funnels and how to
                      leverage funnels for lead generation. Here you will learn
                      how to build your first lead generation funnel.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>What is a Funnel</li>{" "}
                        <li>How to build your Funnel using Page Builders</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingEleven">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    {" "}
                    Mastering Meta Ads (Level 2){" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      In this Module, you will learn the ‘Art of Conversions
                      Campaigns’. The psychology and the strategy behind making
                      people purchase without even talking to them.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>What is a Conversion Campaign</li>{" "}
                        <li>How Retargeting Works</li>{" "}
                        <li>Setting up Pixels to track conversions</li>{" "}
                        <li>Creating a Catalogue</li>{" "}
                        <li>How to run a Conversion Campaigns</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingTwelve">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    {" "}
                    Funnels (Level 2){" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseTwelve"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwelve"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Learn how to create advanced Funnels. This will teach you
                      the WHAT, WHY & HOW about Funnels and how to leverage the
                      state of the art technology to target people effectively
                      and generate high quality leads.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Different types of Funnels & Landing Pages</li>{" "}
                        <li>Copy for Landing Page</li>{" "}
                        <li>Integrating Payment Systems</li>{" "}
                        <li>Making Money as a Funnel Builder</li>{" "}
                        <li>How to build your Own Sales Funnel</li>{" "}
                        <li>
                          {" "}
                          How to leverage Sales Funnels for Better Marketing and
                          Customer Experience{" "}
                        </li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingThirteen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    {" "}
                    AI for Marketing{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseThirteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirteen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      This Module is one of the most interesting and
                      application-oriented approaches that will help you use
                      Artificial Intelligence to your advantage during
                      marketing.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>AI for Marketing</li>{" "}
                        <li>
                          {" "}
                          Creating Names, Logos, Text & Creatives using AI{" "}
                        </li>{" "}
                        <li>Creating Videos using AI</li>{" "}
                        <li>Product Photography using AI</li>{" "}
                        <li>Directory of AI Tools</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingFourteen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    {" "}
                    Google My Business{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseFourteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFourteen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      This Module will cover the Local SEO part and how to
                      create and optimise Google My Business that will help
                      businesses generate leads organically from Google Search
                      and Maps.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>What is Local SEO</li>{" "}
                        <li>How to Create Google My Business</li>{" "}
                        <li>How to do Local SEO Effectively</li>{" "}
                        <li>How to Measure the Ranking</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingFifteen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFifteen"
                    aria-expanded="false"
                    aria-controls="collapseFifteen"
                  >
                    {" "}
                    Mastering Google Ads{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseFifteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFifteen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn the effective methods of ‘Search
                      Engine Marketing’ and ‘PPC’. This also helps you
                      understand how to use the world’s biggest search engine
                      for better marketing purposes.{" "}
                    </p>{" "}
                    <p className="mb-2">Video Campaign</p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>
                          Understanding the benefits of Video Advertising
                        </li>{" "}
                        <li>
                          {" "}
                          Creating and optimising Video Ads for YouTube
                          campaigns{" "}
                        </li>{" "}
                        <li>Shopping Campaign</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                    <p className="mb-2">
                      {" "}
                      Setting up a Google Merchant Center account{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>
                          {" "}
                          Creating Product Listings and optimising Product Feeds
                          for Shopping Campaigns{" "}
                        </li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingSixteen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSixteen"
                    aria-expanded="false"
                    aria-controls="collapseSixteen"
                  >
                    {" "}
                    Influence through LinkedIn{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseSixteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSixteen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn how to use LinkedIn for better
                      marketing and also to build AUTHORITY in any field. This
                      Module is a comprehensive one as this alone can help you
                      connect with the most qualified high-ticket customers.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Why Personal Branding</li>{" "}
                        <li>3 Ways to Build a Personal Brand</li>{" "}
                        <li>Why LinkedIn</li>{" "}
                        <li>LinkedIn Profile Optimization</li>{" "}
                        <li>How to Write Content that works</li>{" "}
                        <li>Sales Navigator</li>{" "}
                        <li>Tools that make life easy</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingSeventeen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeventeen"
                    aria-expanded="false"
                    aria-controls="collapseSeventeen"
                  >
                    {" "}
                    Email Marketing{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseSeventeen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeventeen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn how to use email for better marketing
                      and also to leverage the untapped potential of Email
                      Marketing.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Why Email Marketing</li>{" "}
                        <li>How to build your list </li>{" "}
                        <li>How to write emails that convert</li>{" "}
                        <li>How to send Newsletters</li> <li>Do and Don’ts</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingEighteen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEighteen"
                    aria-expanded="false"
                    aria-controls="collapseEighteen"
                  >
                    {" "}
                    Search Engine Optimization (SEO){" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseEighteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEighteen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn the effective methods of ‘Search
                      Engine Optimization’. You will also learn the Art of
                      making any website/ brand/ business on the internet to
                      rank higher in a niche.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>
                          {" "}
                          Basics of SEO & Role of SEO in Digital Marketing{" "}
                        </li>{" "}
                        <li>Why Should a Business Invest in SEO</li>{" "}
                        <li>On Page SEO</li> <li>Technical SEO</li>{" "}
                        <li>Local SEO</li> <li>Off Page SEO</li>{" "}
                        <li>Competitor Analysis</li> <li>SEO Audit</li>{" "}
                        <li>Data Analysis in SEO</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingNineteen">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNineteen"
                    aria-expanded="false"
                    aria-controls="collapseNineteen"
                  >
                    {" "}
                    Setting up your Venture{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseNineteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNineteen"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn everything about Starting a Company
                      from scratch. This module will help you get clarity on
                      what to do and what not to do when it comes to starting
                      your own venture. Deep knowledge directly from the
                      Experts.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Tax Filing</li> <li>GST</li> <li>Invoicing</li>{" "}
                        <li>Auditing</li> <li>Office Spaces etc.</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingTwenty">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwenty"
                    aria-expanded="false"
                    aria-controls="collapseTwenty"
                  >
                    {" "}
                    Influence through Instagram{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseTwenty"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwenty"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn everything about Organic Social and
                      how to create video content on Instagram that gets
                      attention and builds authority for you in any industry
                      that you work in. You will also understand the formula
                      behind a viral video from the Most Consistent and Value
                      providing couple in the Industry.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>Why you should Create Content</li>{" "}
                        <li>What kind of content should you create</li>{" "}
                        <li>How to structure the Content</li>{" "}
                        <li>How to showcase it for better performance</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="accordion-item">
                {" "}
                <h2 className="accordion-header" id="headingTwentyOne">
                  {" "}
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentyOne"
                    aria-expanded="false"
                    aria-controls="collapseTwentyOne"
                  >
                    {" "}
                    Client Retention{" "}
                  </button>{" "}
                </h2>{" "}
                <div
                  id="collapseTwentyOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentyOne"
                  data-bs-parent="#accordionExample"
                >
                  {" "}
                  <div className="accordion-body">
                    {" "}
                    <p className="mb-2">
                      {" "}
                      Here, you will learn about retaining a client after
                      acquiring them. You will also learn the strategies of
                      retaining a client by adding value to the client.{" "}
                    </p>{" "}
                    <div>
                      {" "}
                      <ul>
                        {" "}
                        <li>How to send a Proposal to the client</li>{" "}
                        <li>How to set and create reports</li>{" "}
                        <li>How to automate reports for the client</li>{" "}
                      </ul>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        </div>
      </div>
      <div
        className="founder-section section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container-fluid pt-3">
          <div className="mb-5">
            <h2 className="section-heading text-center ">
              Gain Expertise With
              <br />
              <span className="color-sec-head">
                High-Demand Digital Marketing Tools
              </span>
            </h2>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z1}></img>
              </div>
            </div>
            <div className="col-lg-2  col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z2}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z3}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z4}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z5}></img>
              </div>
            </div>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z21}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z22}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2 ">
              <div>
                <img className="w-100 h-auto" alt="" src={Z23}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z24}></img>
              </div>
            </div>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z31}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z32}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z33}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z34}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z35}></img>
              </div>
            </div>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z41}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z42}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z43}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z44}></img>
              </div>
            </div>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z51}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z52}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z53}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z54}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z55}></img>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-4 col-6 mb-2 ">
              <div>
                <img className="w-100 h-auto" alt="" src={Z61}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z62}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z63}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z64}></img>
              </div>
            </div>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z71}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z72}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z73}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2 ">
              <div>
                <img className="w-100 h-auto" alt="" src={Z74}></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-2">
              <div>
                <img className="w-100 h-auto" alt="" src={Z75}></img>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={() => {
                setOpen(true);
              }}
              type="button"
              className="btn mt-5 btn-primary contact-btn"
            >
              Become a Digital Entrepreneur
            </button>
          </div>
        </div>
      </div>
      <AcademyModal
        open={open}
        setClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
