import React, { useRef, useState, useEffect } from "react";
import Video from "../../assets/images/si-video-2.mp4";
import Y1 from "../../assets/images/y1.webp";
import Y2 from "../../assets/images/y2.webp";
import Y3 from "../../assets/images/y3.webp";
import Y4 from "../../assets/images/y4.webp";
import Y5 from "../../assets/images/y5.webp";
import Y6 from "../../assets/images/y6.webp";
import GreenTick from "../../assets/images/green-tick.webp";
import RedClose from "../../assets/images/red-close.webp";
import AgencyVideoBanner from "../../assets/images/agency-video-banner.webp";

import { useNavigate } from "react-router-dom";
import { OurClientsLogo } from "../OurClientsLogo";
import { Testimonials } from "../Testimonials";
export const AgencyContent = () => {
  // const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);
 const navigation = useNavigate();
  // useEffect(() => {
  //   const video = videoRef.current;

  //   const handleVideoState = () => {
  //     setIsPlaying(!video.paused);
  //   };

  //   video.addEventListener("play", handleVideoState);
  //   video.addEventListener("pause", handleVideoState);

  //   return () => {
  //     video.removeEventListener("play", handleVideoState);
  //     video.removeEventListener("pause", handleVideoState);
  //   };
  // }, []);

  // const togglePlay = () => {
  //   const video = videoRef.current;
  //   if (video.paused) {
  //     video.play();
  //   } else {
  //     video.pause();
  //   }
  // };


  return (
    <div>
      <div className="wmo mt-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-lg-10 ">
              <h2 className="section-heading mb-5 text-center">
                Would you like us
                <br />
                <span className="color-sec-head">to set up your ads for you?</span>{" "}
              </h2>
              <h6 className="para-color lh-base text-center">Many organisations want performance-based growth, but aren’t ready for that step. <br/>
               That’s why we’re extremely selective on the businesses we work with. <br/> Does your company have what it takes to scale?</h6>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {/* <div className="video-container position-relative">
                <video
                  ref={videoRef}
                  className="w-100 h-auto agency-video"
                  loop
                  muted
                  onClick={togglePlay}
                >
                  <source src={Video} type="video/mp4" />
                </video>
                {isPlaying ? (
                  <div
                    className="pause-button posi-absolute-btn"
                    onClick={togglePlay}
                  >
                    <i className="fa fa-pause icon-playpause"></i>{" "}
                  
                  </div>
                ) : (
                  <div
                    className="play-button posi-absolute-btn"
                    onClick={togglePlay}
                  >
                    <i className="fa fa-play icon-playpause"></i>{" "}
               
                  </div>
                )}
              </div> */}
              <div>
                <img className="w-100 h-auto" src={AgencyVideoBanner} alt=""></img>
              </div>
            </div>
          </div>
          <div className="row my-5 justify-content-center">
            <div className="col-lg-10 ">
              <h2 className="section-heading mb-4 text-center">
                Ready to <span className="color-sec-head">scale your business?</span>{" "}
              </h2>
              <h6 className="para-color lh-base text-center">
                Get campaigns that convert from the same agency who’s been hired by companies like <span className="font-bold">Unilever, Microsoft Naturals, Ricola, Haagen-dazs, Indian Institute of Technology, VGP Housing, Asus, Singapore University, DIYA Robotics and many more…</span>
              </h6>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => {
                    navigation("/ContactUs");
                  }}
                  className="btn mt-5 ms-3 contact-btn"
                >
                  Work With Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-clients section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container our-clients-inner-sec">
          <div className="row justify-content-center">
            <div className="col-lg-7 mb-5">
              <h2 className="section-heading text-white text-center">
                WHO HAVE
                <span className="color-sec-head">
                  {" "}
                  WE HAVE <br /> HELPED?
                </span>
              </h2>
            </div>
          </div>
          <div>
            <OurClientsLogo/>
          </div>
        </div>
      </div>
      <div className="wmo mt-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row mt-5 mb-3 justify-content-center">
            <div className="col-lg-10 ">
              <h2 className="section-heading mb-4 text-center">
                WHY <span className="color-sec-head">WORK WITH US?</span>
              </h2>
              <h6 className="para-color font-bold  lh-base mb-4 text-center">
                Over $2M in ad spend for more than 200 brands by generating 800K+ Leads and $16M in revenue with Paid Ads
              </h6>
              <h3 className="text-secondary font-bold fst-italic my-1 text-center">“ Because we are not only the best but also the best known “</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-6 col-md-6 mb-3">
                  <div className="agency-card ">
                    <div className="text-start pb-2">
                      <img className="step-icon-2" src={GreenTick} alt=""></img>
                    </div>
                    <h5 className="section-heading agency-content-height lh-base  fs-5">The Scaling system</h5>
                    <p className="res-font text-secondary agency-content-height">
                      Discover the Scaling System we use to help our clients get consistent leads, calls, and clients, while averaging a<span className="font-bold"> 3-4x</span> ROAS.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-3">
                  <div className="agency-card ">
                    <div className="text-start pb-2">
                      <img className="step-icon-2" src={GreenTick} alt=""></img>
                    </div>
                    <h5 className="section-heading agency-content-height lh-base  fs-5">A World-Class Marketing Team</h5>
                    <p className="res-font text-secondary agency-content-height">Get a World-Class Marketing Team who will write your scripts, help you film the ads with you, edit all the videos, and set up, launch, manage, optimize, and scale all your campaigns for you!</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-3">
                  <div className="agency-card border-danger">
                    <div className="text-start pb-2">
                      <img className="step-icon-2" src={RedClose} alt=""></img>
                    </div>
                    <h5 className="section-heading agency-content-height lh-base  fs-5">Escape {" "}<span className="text-lowercase font-bold">the</span>{" "}  Saturated, Unpredictable & Stressful Red Ocean of Facebook Ads</h5>
                    <p className="res-font text-secondary agency-content-height">So that you can start taking advantage of the shockingly untapped blue ocean of Paid Ads!</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-3">
                  <div className="agency-card border-danger">
                    <div className="text-start pb-2">
                      <img className="step-icon-2" src={RedClose} alt=""></img>
                    </div>
                    <h5 className="section-heading agency-content-height lh-base  fs-5">Avoid having ALL your eggs in ONE basket</h5>
                    <p className="res-font text-secondary agency-content-height">By working with a team who can help you scale your business on Paid Ads.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={() => {
                navigation("/ContactUs");
              }}
              className="btn mt-3 ms-3 contact-btn"
            >
              Work With Us
            </button>
          </div>
        </div>
      </div>
      <div className="wmo mt-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row my-5 justify-content-center">
            <div className="col-lg-8 ">
              <h2 className="section-heading mb-0 text-center">
                What can <span className="color-sec-head">we do for you?</span>
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-4 col-md-4 mb-3 ">
                  <div className="m-2">
                    <img className="w-100 h-auto" src={Y1} alt=""></img>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mb-3 ">
                  <div className="m-2">
                    <img className="w-100 h-auto" src={Y2} alt=""></img>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mb-3">
                  <div className="m-2">
                    <img className="w-100 h-auto" src={Y3} alt=""></img>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mb-3 ">
                  <div className="m-2">
                    <img className="w-100 h-auto" src={Y4} alt=""></img>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mb-3 ">
                  <div className="m-2">
                    <img className="w-100 h-auto" src={Y5} alt=""></img>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mb-3 ">
                  <div className="m-2">
                    <img className="w-100 h-auto" src={Y6} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5 justify-content-center">
            <div className="col-lg-8 ">
              <h2 className="section-heading mb-5 text-center">
                Here's <span className="color-sec-head">How It Works</span>
              </h2>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="">
                    <h5 className="color-sec-head font-bold border-start border-red-color ps-2 mb-4 border-5">We'll do all the confusing "techie stuff for you".</h5>
                    <ul className="ps-3">
                      <li>
                        <p className="founder-para">We'll make sure your business page is set up right. </p>
                      </li>
                      <li>
                        <p className="founder-para">Then we'll make sure your ads manager is set up right. </p>
                      </li>
                      <li>
                        <p className="founder-para">Then we'll create your Facebook pixel, lead pages and make sure that's set up right. </p>
                      </li>
                      <li>
                        <p className="founder-para">YOU DO NOTHING during this part. (It's super boring and kind of awful unless you like tech stuff.)</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <h5 className="color-sec-head font-bold border-start border-red-color ps-2 mb-4 border-5">We'll Craft An Ad That Attracts The People You Want</h5>
                    <ul className="ps-3">
                      <li>
                        <p className="founder-para">You'll work with us during this part and by "work", We mean "answer some questions so we make sure we're doing a good job for you."</p>
                      </li>
                      <li>
                        <p className="founder-para">First, we'll find out exactly who YOU want to target.</p>
                      </li>
                      <li>
                        <p className="founder-para">Then we'll find out what THEY want.</p>
                      </li>
                      <li>
                        <p className="founder-para">Then we'll create an ad that makes THEM want it from YOU.</p>
                      </li>
                      <li>
                        <p className="founder-para">We do the copy, the image (or video), and everything.</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <h5 className="color-sec-head font-bold border-start border-red-color ps-2 mb-4 border-5">We'll Build an Account FOR YOU in YOUR Facebook</h5>
                    <ul className="ps-3">
                      <li>
                        <p className="founder-para">We'll set up your ad, do the targeting, and everything.</p>
                      </li>
                      <li>
                        <p className="founder-para lh-base fs-6">When we're done, it'll be ready to go and you can take it from there.</p>
                      </li>
                      <li>
                        <p className="founder-para lh-base fs-6">Plus, we'll set up an automated lead generation system and nurturing system that will help you get more clients.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={() => {
                navigation("/ContactUs");
              }}
              className="btn  ms-3 contact-btn"
            >
              Work With Us
            </button>
          </div>
        </div>
      </div>
      <div className="testimonial-section" data-aos="fade-up" data-aos-delay="150">
        <div className="container-fluid pb-5 px-4">
          <div className="row">
            <div className="col-lg-5">
              <h2 className="section-heading mt-5">
                What Our <br />
                <span className="color-sec-head">Clients Say</span>{" "}
              </h2>
              <p className="para-color ">Delivering top-notch service to our clients is great, but what really makes us happy is hearing how our work makes a difference for our clients and their businesses.</p>
              <p className="para-color ">
                {" "}
                We like to think of this as <span className="font-bold">The Eagle Impact</span>.
              </p>
            </div>
            <div className="col-lg-7">
               <Testimonials/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
