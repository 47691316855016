import React, { useState } from "react";
import CustomModal from "../CustomModal";
import ThankYouModal from "../ThankYouModal";
import axios from "axios";
const BrochureDownloadModal = ({ open, setClose }) => {
  const [thankYouModal, setThankYouModal] = useState(false);
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = async () => {
    let flag = true;
    setLoading(true);
    let validate = {};
    const currentDate = new Date();

    // Validation logic
    if (!data.name?.length > 0) {
      flag = false;
      validate.name = "Name is Required";
    }
    if (
      !(
        data.phoneNumber?.length === 13 ||
        data.phoneNumber?.length === 12 ||
        data.phoneNumber?.length === 10
      )
    ) {
      flag = false;
      validate.phoneNumber = "Phone Number is Required";
    }
    if (!data.emailId?.length > 0) {
      flag = false;
      validate.emailId = "Email is Required";
    }
    if (!data.city?.length > 0) {
      flag = false;
      validate.city = "City is Required";
    }

    if (flag) {
      try {
        let inputValue = {
          Name: data.name,
          "Phone Number": data.phoneNumber,
          "Email Id": data.emailId,
          City: data.city,
          Date: currentDate.toDateString(),
        };

        // const Payload = [
        //   {
        //     Attribute: "FirstName",
        //     Value: data.name,
        //   },
        //   {
        //     Attribute: "EmailAddress",
        //     Value: data.emailId,
        //   },
        //   {
        //     Attribute: "Phone",
        //     Value: data.phoneNumber,
        //   },
        //   {
        //     Attribute: "City",
        //     Value: data.city,
        //   },
        //   {
        //     Attribute: "Source",
        //     Value: "Web-Academy-Download-Broucher",
        //   },
        // ];


        // try {
        //   const response = await axios.post(
        //     "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r6bb21b3cf1518343d1981ce6646b844f&secretKey=d58ccce3a29496917e48f84d2f40d31061c6b781",
        //     Payload,
        //     {
        //       headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   );
        // } catch (e) {
        //   console.error("Error sending to Leadsquared:", e);
        // }

        let form_data = new FormData();
        for (let key in inputValue) {
          form_data.append(key, inputValue[key]);
        }
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbz81RoHjXOnby54PoFximIAPtbmFFnFsr0u64g0vK1bxr82WxNpV2mzQGk48rgCWH874A/exec",
          {
            method: "POST",
            body: form_data,
          }
        );

        await response.text();
        setLoading(false);
        setClose();
        setData({});
        setThankYouModal(true);

        // window.open(
        //   "https://socialeagle.in/SEA-Cohort-Brochure.pdf",
        //   "_blank"
        // );
        download(
          "https://socialeagle.in/SEA-Cohort-Brochure.pdf",
          "SEA-Cohort-Brochure.pdf"
        );
      } catch (error) {
        setLoading(false);
        console.error("Error writing to Google Sheets:", error);
      }
    } else {
      setLoading(false);
      setValidation(validate);
    }
  };

  const download = (path, filename) => {
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const setFieldData = (e, key) => {
    setData({ ...data, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <div>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setClose(false);
          setValidation({});
        }}
      >
        <div className="modal-content contact-container w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className=" close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setClose(false);
                  setValidation({});
                }}
              >
                <i class="fa-solid fa-xmark"></i>
              </span>
              <div className="row d-flex align-items-center justify-content-center  my-4">
                <div className="container px-lg-5 px-3">
                  <div className="row justify-content-center  height-modal ">
                    <h4 className="text-center font-bold">Send us a Message</h4>
                    <div className="col-lg-12 justify-content-center">
                      <div className="mb-2">
                        <label htmlFor="name" className="font-bold mb-2">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          onChange={(e) => {
                            setFieldData(e.target.value, "name");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.name}
                        </p>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="phno" className="font-bold mb-2">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phno"
                          onChange={(e) => {
                            setFieldData(e.target.value, "phoneNumber");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.phoneNumber}
                        </p>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="email" className="font-bold mb-2">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          onChange={(e) => {
                            setFieldData(e.target.value, "emailId");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.emailId}
                        </p>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="city" className="font-bold mb-2">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          onChange={(e) => {
                            setFieldData(e.target.value, "city");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.city}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn  mt-3  w-100 btn-primary contact-btn"
                      onClick={(inputIndex) => validate(inputIndex)}
                    >
                      {loading ? (
                        <i className="fa fa-circle-notch fa-solid"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
    </div>
  );
};

export default BrochureDownloadModal;
