import React from "react";

export const ContactBanner = () => {
  return (
    <div data-aos="fade-up" data-aos-delay="150">
      <div className="bg-video-wrap2 contact-banner">
        {/* <div className="overlay"></div> */}
        <div className="home-video-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="text-center">
                  <h1 className="banner-heading">Contact</h1>

                  <p className="text-white text-center font-bold text-capitalize fs-4 mb-0">
                    Come Visit &{" "}
                    <span className="font-bold text-lowercase">the</span> Coffee{" "}
                    <span className="font-bold text-lowercase">is on</span> Us
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
