import React, { useEffect } from "react";
import { Navbar } from '../Components/Navbar/Navbar'
import { AgencyBanner } from '../Components/Agency/AgencyBanner'
import { AgencyContent } from '../Components/Agency/AgencyContent'
import { Footer } from '../Components/Footer/Footer'

export const Agency = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
        <Navbar/>
        <AgencyBanner/>
        <AgencyContent/>
        <Footer/>
    </div>
  )
}
