import React from "react";

export const ToursandWorkshopsBanner = () => {
  return (
    <div>
      <div className="bg-video-wrap2 toursworkwithus-banner">
        {/* <div className="overlay"></div> */}
        <div className="home-video-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="banner-heading text-uppercase">Tours & workshops</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
