import React, { useState } from "react";
import TourImg1 from "../../assets/images/tour-img1.webp";
import TourImg2 from "../../assets/images/tour-img2.webp";
import BrochureDownloadModal from "../Academy/BroucherDownloadModal";
export const ToursandWorkshopsContent = () => {
  const [open, setOpen] = useState(false);
  
  return (
    <div>
      <div className="work-cul my-5">
        <div className="container">
          <div>
            <h2 className="section-heading fs-3 mb-4">
              Come Visit us & Experience the Temple of Energy
            </h2>
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-lg-12">
              <div className="ps-lg-0 ps-0">
                <ul>
                  <li>
                    <p className="founder-para">
                      We love sharing what we learnt and experienced with
                      others.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Come learn about everything in digital (from digital
                      mindset to advanced transformation) at Social Eagle—and
                      take that knowledge home with you.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      We don't keep our methods a secret. We really enjoy
                      sharing what we've learned. Our main goal is to "Impact
                      people’s life leveraging the Digital ecosystem".
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      This means making life better for everyone, not just
                      ourselves.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Sharing who we are and what we do is a big part of our way
                      of doing things.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      We're excited that lots of people visit us every now and
                      then to learn more.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Some want a quick Energy boost (As people see our office
                      as a temple of energy).
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Others want to dive deeper and learn our strategies in a
                      class or a practical workshop.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      More and more visitors want an intense experience with our
                      coaching and mentoring.
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Our aim is for everyone who visits to go back home feeling
                      inspired and ready to live abundantly in their lives.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <h2 className="section-heading fs-3 mb-4">
              Get Notified about our Next Tour.
            </h2>
            <button type="button" className="btn mt-3 btn-primary contact-btn"
            onClick={() => setOpen(true)}
            >
              Get notified
            </button>
          </div>
        </div>
      </div>
      <div className="section-gap ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="text-center">
                <h2 className="section-heading fs-3 lh-base  mb-3">
                  Social Eagle{" "}
                  <span className="color-sec-head">Office Tour</span>
                </h2>
                <h6 className=" lh-base  mb-4">
                  Explore the Temple of energy (as people call it). our blend of
                  joyful and disciplined methods that empower our team to
                  collaborate effectively, learn from mistakes quickly,
                  prioritize end users, and maintain a healthy and happy work
                  environment.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap ">
        <div className="container  ">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="">
                <h2 className="section-heading fs-3 mb-4">BYOB (Build your Own Business):</h2>
                <p className="founder-para">
                  One of a kind Workshop for people who wants to build their own
                  business by leveraging digital space.
                </p>
                <p className="founder-para">
                  Come spend 90 - 120 minutes peaking into the world of digital
                  marketing to learn about the opportunities to build your own
                  business and attain the freedom of time, money, travel and
                  most importantly peace of mind.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="">
                <img className="w-100 h-auto" src={TourImg1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="">
                <img className="w-100 h-auto" src={TourImg2} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6  my-auto">
              <div className="">
                <h2 className="section-heading fs-3 mb-4 mt-lg-0 mt-4">Meet & Greet:</h2>
                <p className="founder-para">
                  Our Founder invites his lovable followers to the temple of
                  energy and share his experiences and story of his life.
                </p>
                <p className="founder-para">
                  You will learn how to actually be successful in life and build
                  a business using digital marketing without compromising on the
                  mental health.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BrochureDownloadModal
        open={open}
        setClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
