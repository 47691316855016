import React from "react";
import BlogImg5 from "../../assets/images/blog5.webp";
import BlogTrainer from "../../assets/images/blog-trainer.webp";
import BlogTrainer2 from "../../assets/images/blog-trainer2.webp";
import BlogBrand from "../../assets/images/blog-brand.webp";
import BlogBrand2 from "../../assets/images/blog-brand2.webp";
import CompleteDigitalMarketing from "../../assets/images/complete-digital-marketing-12.webp";
import { Link } from "react-router-dom";
export const DMC12in1 = () => {
  return (
    <div>
      <div>
        <div
          className="bg-video-wrap2 blog-details-banner"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="home-video-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <h1 className="banner-heading mb-2 text-uppercase">
                    The Complete Digital Marketing Course 12 – Courses in 1
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-5 section-gap"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="blog-content ">
                  <div className="row">
                    <div className="col-lg-8">
                      <img src={BlogImg5} className="w-100 h-auto"></img>
                    </div>
                  </div>
                  <div className="d-flex my-3 align-items-center">
                    <span className="me-2">
                      <i class="fa-regular text-secondary fa-calendar-days"></i>
                    </span>
                    <p className="text-secondary mb-0">August 25, 2023</p>
                  </div>
                  <h2 className="font-bold mb-4">
                    The Complete Digital Marketing Course 12 – Courses in 1
                  </h2>
                  <div className="">
                    <p>
                      Hello Digital enthusiasts! Are you excited to explore the
                      amazing world of Internet marketing? Well, get ready,
                      because we’re about to share some exciting information.
                    </p>
                    <p>
                      Imagine this: a super digital marketing course that’s
                      actually 12 courses in one! Yes, you heard correctly –
                      it’s like getting twelve courses but only paying for one.
                      Get ready for an exciting online journey!
                    </p>
                    <h3 className="font-bold mb-4">
                      Introduction - Let's Get Digital:
                    </h3>
                    <p>
                      Let’s start with great excitement! We’re talking about a
                      game-changing experience here. Have you ever wondered what
                      makes those online ads so irresistible? Or how brands
                      manage to be everywhere on social media?
                    </p>
                    <p>
                      This course shares secrets and gives you special abilities
                      to succeed online.
                    </p>
                    <p>
                      <span className="font-bold">
                        Why choose Social Eagle?
                      </span>{" "}
                      We believe in education and hands-on experience for
                      success. Our training covers technical skills and industry
                      knowledge, guided by dedicated instructors.
                    </p>
                    <p>
                      We’re committed to helping you excel in digital marketing.
                      With personalized guidance, mentorship, and valuable
                      support, Social Eagle Academy ensures your journey toward
                      a fulfilling career. Don’t wait, start your path to
                      success today!
                    </p>
                    <div className="row justify-content-center my-3">
                      <div className="col-lg-8">
                        <img
                          className="w-100 h-auto"
                          src={CompleteDigitalMarketing}
                          alt=""
                        />
                      </div>
                    </div>
                    <h3 className="font-bold mb-4">
                      Syllabus of Digital Marketing Course
                    </h3>
                    <p>
                      Let us have a into the Syllabus of our comprehensive 12 in
                      1 Digital Marketing course.
                    </p>
                    <h3 className="font-bold mb-4">
                      1) Fundamentals and Objectives of Marketing:
                    </h3>
                    <p>
                      Let’s start by going back to the beginning. Think of
                      marketing like a puzzle, and these are the essential
                      pieces. We’ll explain marketing details step by step, so
                      you’ll know how to connect everything and make your brand
                      stand out.
                    </p>
                    <p>
                      Learn Marketing Basics, Digital Mindset, and Platforms.
                      Grasp Digital Marketing’s Importance. Define Business,
                      Marketing, and Digital Marketing Objectives.
                    </p>
                    <h3 className="font-bold mb-4">
                      2) Social Media Marketing:
                    </h3>
                    <p>
                      Be a social butterfly. Are you addicted to scrolling
                      through Facebook, Instagram, and Twitter? Well, guess
                      what? Now you can turn that addiction into a superpower.
                      Learn how to use these platforms to make your brand the
                      talk of the town.
                    </p>
                    <p>
                      Social Media Management: Understand its importance.
                      Schedule and share content strategically. Use varied post
                      types and an effective content structure. Master hashtags
                      and keywords for optimal results.
                    </p>
                    <h3 className="font-bold mb-4">3) Copywriting:</h3>
                    <p>
                      Get ready for your keyboards, friends, as we’re about to
                      learn persuasive writing. Imagine it’s like using a magic
                      trick in marketing. From intriguing titles to words that
                      make things sound great, you’ll have the power to sell
                      things really well.
                    </p>
                    <p>
                      Copywriting: Craft persuasive content. Diverse methods and
                      styles. Evergreen headline formula. Turning copywriting
                      into income.
                    </p>
                    <h3 className="font-bold mb-4">
                      4) Creatives and Designs:
                    </h3>
                    <p>
                      You don’t need to be an art school graduate to nail this.
                      We’re talking about creating eye-popping visuals that make
                      people stop mid-scroll. Whether it’s designing a logo or
                      creating graphics for your posts, Designs that resonate:
                      Learn the 3-Sec formula.
                    </p>
                    <p>
                      Master design principles, CANVA, and color psychology.
                      Excel in Facebook and Google’s creative dimensions. Turn
                      your design skills into a profitable venture. We’ve got
                      you covered.
                    </p>
                    <h3 className="font-bold mb-4">
                      5) Artificial intelligence in Marketing:
                    </h3>
                    <p>
                      The future is now. Let’s get futuristic and talk about AI.
                      No, it’s not just for sci-fi movies. AI in Marketing:
                      Understand AI’s role. Explore AI’s impact on marketing.
                    </p>
                    <p>
                      Harness AI tools for simplified marketing. Discover how AI
                      can supercharge your marketing efforts, helping you
                      connect with customers on a whole new level.
                    </p>
                    <h3 className="font-bold mb-4">
                      6) Customer avatar and Business Models:
                    </h3>
                    <p>
                      Meet Your Group – Ever pretended to be a detective? Now
                      you can. We’re revealing the hidden details about your
                      best customers – who they are, what they like, and how you
                      can make them really like your brand.
                    </p>
                    <p>Customer Avatar & Business Models:</p>
                    <ul>
                      <li>Master AIDA & UPSYD models for Customer Journey</li>
                      <li>Define Audience, Conduct Market Research</li>
                      <li>Formulate Audience Hypothesis</li>
                      <li>
                        Understand Market Temperature (COLD, WARM, HOT) And
                        we’re adding some clever business tricks to make things
                        even better.
                      </li>
                    </ul>
                    <h3 className="font-bold mb-4">
                      7) Search Engine Optimization (SEO):
                    </h3>
                    <p>
                      Be Google’s Best Friend – Have a website? Great! But if
                      it’s buried on page 10 of Google, no one’s finding it. SEO
                      Essentials:
                    </p>
                    <ul>
                      <li>Impact on Digital Marketing & Sales Funnel.</li>
                      <li>Business benefits of SEO investment.</li>
                      <li>On-Page, Technical, Local, Off-Page SEO. </li>
                      <li>
                        Competitor analysis, SEO audit, and data insights.
                      </li>
                      <li>
                        Boost conversions through conversion rate optimization.
                      </li>
                    </ul>
                    <h3 className="font-bold mb-4">8) Google Ads:</h3>
                    <p>
                      Be Google’s Best Friend – Google isn’t just a search
                      engine; it’s also a goldmine for advertising. We’re
                      talking about creating ads that people can’t help but
                      click on. Google Ads & Conversion Tracking:
                    </p>
                    <ul>
                      <li>
                        Utilise Tag Manager & Analytics for Conversion Tracking.
                      </li>
                      <li>
                        Explore Google Ads types: Search, Display, Video,
                        Shopping.
                      </li>
                      <li>Craft Search ads: Keywords, ad groups, and copy.</li>
                      <li>
                        Create impactful display ads with precise targeting.
                      </li>
                      <li>
                        Optimise video ads on YouTube for better engagement.
                      </li>
                    </ul>
                    <p>
                      Set up shopping campaigns with optimised product feeds.
                      Get ready to be the puppeteer pulling the strings of those
                      clickable ads.
                    </p>
                    <h3 className="font-bold mb-4">9) Facebook Ads:</h3>
                    <p>
                      Scroll-Stopping Strategies – Get your brand in front of
                      the right people, even as they’re scrolling through cat
                      memes:
                    </p>
                    <ul>
                      <li>
                        Facebook Ads Level 1: • Start with Profiles, Pages &
                        Best Practices.
                      </li>
                      <li>Learn Meta Ad Manager basics & Ad account setup.</li>
                      <li>Explore Facebook Campaigns and Lead Generation.</li>
                      <li>Master Copy, Creative, and CTA for your first ad.</li>
                      <li>Address common ad issues and understand funnels</li>
                    </ul>
                    <p>
                      Master the art of crafting Facebook ads that make people
                      stop, stare, and take action.
                    </p>
                    <h3 className="font-bold mb-4">10) Sales funnel:</h3>
                    <p>
                      The Journey to YES: Picture this: a funnel that pours in
                      potential customers and spits out happy buyers. Sales
                      funnels are step-by-step paths that guide people through
                      buying something.
                    </p>
                    <p>
                      There are various types of funnels and pages used to
                      attract and convince customers. A landing page is where
                      the sales pitch happens, and the words you use there are
                      called copy. To take payments smoothly, you link payment
                      systems like credit cards.
                    </p>
                    <p>
                      As a funnel builder, you earn money by creating effective
                      funnels that help businesses sell more. We’re breaking
                      down the sales journey step by step, making sure your
                      customers go from “maybe” to “heck yes!”
                    </p>
                    <h3 className="font-bold mb-4">
                      11) Instagram and LinkedIn Usage in Business:
                    </h3>
                    <p>
                      Instagram isn’t just for sharing food pics, and LinkedIn
                      isn’t just a digital resume. Using Instagram for business
                      means using it to help your company. You can use reels to
                      make short and fun videos for your business.
                    </p>
                    <p>
                      Planning content is about deciding what to post and when.
                      The 10-step formula guides your actions on Instagram. It
                      can also bring in leads and help turn them into customers.
                    </p>
                    <p>
                      Discover how to use these platforms strategically to grow
                      your business, connect with influencers, and make
                      meaningful connections.
                    </p>
                    <h3 className="font-bold mb-4">
                      12) Automation in 'GOD' Mode:
                    </h3>
                    <p>
                      Work Smarter, Not Harder: Tired of doing the same tasks
                      over and over again? Say hello to automation! Automation
                      in ‘GOD’ mode refers to supercharged automation. It
                      involves using tools to make tasks automatic, like sending
                      emails.
                    </p>
                    <p>
                      These tools are like helpers for automation. Email
                      marketing is one area where automation is often used to
                      send emails to many people at once.
                    </p>
                    <p>
                      We’ll show you how to put your marketing on autopilot, so
                      you can focus on sipping your favourite beverage while
                      your brand gets some serious attention.
                    </p>
                    <h3 className="font-bold mb-4">
                      100% placement assistance:
                    </h3>
                    <p>
                      Committed to learners’ success with 100% guaranteed
                      placement assistance, empowering them to achieve their
                      career goals and reach their full potential.
                    </p>
                    <p>
                      Take the first step towards your dream career with our
                      placement assistance – providing personalised guidance and
                      support for endless opportunities in digital marketing.
                    </p>
                    <p>
                      Placement Assistance Eligibility – Regular attendance at
                      classes is mandatory to ensure a comprehensive
                      understanding of the curriculum. Timely submission of
                      real-time projects will showcase your skills and
                      dedication to the program.
                    </p>
                    <p>
                      A uniquely crafted resume will highlight your strengths
                      and make you stand out to potential employers. An enhanced
                      portfolio demonstrating your practical skills and projects
                      completed during the program will help you showcase your
                      capabilities to potential recruiters.
                    </p>
                    <h3 className="font-bold mb-4">Our trainers:</h3>
                    <div className="row justify-content-center my-3">
                      <div className="col-lg-8">
                        <img
                          className="w-100 h-auto"
                          src={BlogTrainer}
                          alt=""
                        />
                        <img
                          className="w-100 h-auto"
                          src={BlogTrainer2}
                          alt=""
                        />
                      </div>
                    </div>
                    <p>
                      Who’s holding the mic? Only the rock stars of the
                      marketing world!
                    </p>
                    <p>
                      Industry Experts – Experienced and inspiring digital
                      marketing professionals share real-world knowledge and
                      expertise, empowering learners with practical skills and
                      insights for success.
                    </p>
                    <p>
                      Digital Marketing Course in Chennai by Industry Experts
                      Our trainers aren’t just experts; they’re real-life
                      wizards who’ll guide you through every twist and turn of
                      this digital rollercoaster.
                    </p>
                    <p>
                      <span className="font-bold">Dharaneetharan G D </span>{" "}
                      Founder & CEO – Social Eagle Marketing & Business Growth
                      Expert Dharaneetharan G D is a digital expert, and founder
                      of Social Eagle, a top digital agency, and 21DC Community.
                    </p>
                    <p>
                      Brands that trusted us like Nestle, Naturals, Haagen-Dazs,
                      and Asus ( Inspiring Innovation President Perfection ) and
                      motivates as a speaker, impacting over 2 lakh people.
                      Awarded for social media, he’s an author and aims to
                      uplift a million Tamil lives with his work.
                    </p>
                    <p>
                      <span className="font-bold">Vishnu Hari CTO:</span> Social
                      Eagle E-commerce & Performance Marketing Expert
                    </p>
                    <p>
                      <span className="font-bold">Sudharsanan Ganapathy: </span>
                      Founder & CEO – The Social Company Personal Branding
                      Expert
                    </p>
                    <p>
                      <span className="font-bold">Ashkar Gomez:</span> Founder –
                      7 Eagles SEO & Growth Marketing Expert
                    </p>
                    <h3 className="font-bold mb-4">Brands that trust us</h3>
                    <div className="row justify-content-center my-3">
                      <div className="col-lg-8">
                        <img
                          className="w-100 h-auto"
                          src={BlogBrand}
                          alt=""
                        />
                        <img
                          className="w-100 h-auto"
                          src={BlogBrand2}
                          alt=""
                        />
                      </div>
                    </div>
                    <h3 className="font-bold mb-4">Brands that trust us</h3>
                    <p>Your Digital Odyssey Awaits – There you have it, digital explorers! The Complete Digital Marketing Course: 12 Courses in 1 is your ticket to conquering the digital realm. Say goodbye to confusion and hello to digital dominance.</p>
                    <p>Get ready to unlock your inner marketing maestro and take your brand places you’ve only dreamed of. It’s time to make waves in the digital ocean – are you in?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-gap text-center">
          <Link to="/ContactUs">
            <button type="button" className="btn btn-primary contact-btn">
              Get In Touch
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
