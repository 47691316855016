import React, { useState } from "react";

import AdvisoryModal from "./AdvisoryModal";

export const AdvisoryBanner = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="bg-video-wrap2 advisory-banner" data-aos="fade-up" data-aos-delay="150">
        {/* <div className="overlay"></div> */}
        <div className="home-video-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="banner-heading mb-2 text-uppercase">ADvisory</h1>
                <h1 className="banner-heading fs-2 mb-2 text-uppercase">Succeed More…  Suffer Less</h1>
                <p className="text-white text-center text-capitalize mb-0">
                  We take consulting <span className="text-lowercase">to the</span>  next level with Battle-Tested <br /> and Proven Results
                </p>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => {
                      setOpen(true);
                    }}
                    className="btn mt-4 btn-primary contact-btn rounded-0"
   
                  >
                    Get In Touch
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdvisoryModal
        open={open}
        setClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
